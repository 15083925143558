import { Account, AccountRights, AccountRole } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    Alert,
    Button,
    ContentLoader,
    EmptyCTA,
    List,
    PartnerInvitationModalForm,
    SectionTitle,
    addQueryParamsToUrl,
    invitePartner,
    openNotificationWithIcon,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useModal,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PartnerCard, ReceivedInvitationCard, SentInvitationCard, ViewWrapper } from "../../../components";
import { MenuItemKeys } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import {
    URL,
    getAccountPartners,
    getAccountUserRole,
    getPendingInvitationsByAccountId,
    getPendingPartnerInvitationsByEmailAndDomainId
} from "../../../services";

export default function PartnersView(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { isOpen, doOpen, doClose } = useModal();
    const { userProfile, selectedAccount: account, accountUser } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [partners, setPartners] = useState<Account.Partner[]>();
    const [receivedInvitations, setReceivedInvitations] = useState<Account.PartnerInvitation[]>();
    const [sentInvitations, setSentInvitations] = useState<Account.PartnerInvitation[]>();
    const navigate = useNavigate();
    const canUserInvite = useHasAccountRights(AccountRights.WRITE);
    const isContributor = getAccountUserRole(accountUser?.rights) === AccountRole.CONTRIBUTOR;

    useEffect(() => {
        if (userProfile?.email) {
            return getPendingPartnerInvitationsByEmailAndDomainId(userProfile.email, whiteLabelDomain?.id, setReceivedInvitations);
        }
        return () => {};
    }, [userProfile?.email, whiteLabelDomain?.id]);

    useEffect(() => {
        if (account) {
            const sentInvitationsUnsubscribe = getPendingInvitationsByAccountId(account.id, setSentInvitations);
            const accountPartnersUnsubscribe = getAccountPartners(account.id, setPartners);
            return () => {
                sentInvitationsUnsubscribe();
                accountPartnersUnsubscribe();
            };
        }
        return () => {};
    }, [account]);

    if (receivedInvitations === undefined || partners === undefined || sentInvitations === undefined || !userProfile) {
        return <ContentLoader size="large" initialState="spinning" />;
    }

    if (!account) {
        return <ContentLoader />;
    }

    const goToDetail = (partnerAccountId: string) => {
        navigate(addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.PARTIES, id: partnerAccountId }));
    };

    async function sendPartnerInvitation(email: string, shouldNotifyByEmail: boolean) {
        try {
            if (!account) {
                return;
            }
            await invitePartner(email, account.id, shouldNotifyByEmail);
            openNotificationWithIcon({ type: "success", description: lang.shared.inviteSuccessMessage });
        } catch (e) {
            openNotificationWithIcon({ type: "error", description: lang.shared.inviteErrorMessage });
        }
    }

    return (
        <ViewWrapper title={lang.accountSettings.partiesView.name}>
            <Alert.Info title={lang.accountSettings.partiesView.whatIs} message={lang.accountSettings.partiesView.definition} />
            {(!!partners.length || !!receivedInvitations.length || !!sentInvitations.length) && (
                <div className="flex place-content-end">
                    {canUserInvite && (
                        <>
                            <Button onClick={doOpen} isLoading={false} isDisabled={isContributor}>
                                {lang.parties.newParty}
                            </Button>
                            <PartnerInvitationModalForm
                                isOpen={isOpen}
                                account={account}
                                onFinish={sendPartnerInvitation}
                                partners={partners}
                                receivedInvitations={receivedInvitations}
                                sentInvitations={sentInvitations}
                                onClose={doClose}
                            />
                        </>
                    )}
                </div>
            )}
            {(!!receivedInvitations.length || !!sentInvitations.length) && (
                <div className="space-y-2">
                    <SectionTitle title={lang.accountSettings.partiesView.invitations} />
                    <List list={receivedInvitations} render={invitation => <ReceivedInvitationCard invitation={invitation} account={account} />} />
                    <List list={sentInvitations} render={invitation => <SentInvitationCard invitation={invitation} accountId={account.id} />} />
                    <SectionTitle title={lang.accountSettings.partiesView.name} />
                </div>
            )}
            {!partners.length && !receivedInvitations.length && !sentInvitations.length && (
                <>
                    <EmptyCTA
                        title={lang.accountSettings.partiesView.noPartyYet}
                        buttonProps={{
                            children: lang.parties.newParty,
                            icon: AddIcon,
                            onClick: doOpen,
                            isDisabled: !canUserInvite || isContributor,
                            isLoading: false,
                            ...(!canUserInvite && { title: lang.accountSettings.partiesView.reportToAdmin })
                        }}
                    />
                    <PartnerInvitationModalForm
                        isOpen={isOpen}
                        onClose={doClose}
                        account={account}
                        onFinish={sendPartnerInvitation}
                        partners={partners}
                        receivedInvitations={receivedInvitations}
                        sentInvitations={sentInvitations}
                    />
                </>
            )}
            <List list={partners} render={partner => <PartnerCard partner={partner} accountId={account.id} goToDetail={goToDetail} />} />
        </ViewWrapper>
    );
}
