import { AccountApiKey, AccountRights, AccountUser } from "@vaultinum/vaultinum-api";
import {
    AddIcon,
    Alert,
    Button,
    ContentLoader,
    KeyIcon,
    List,
    ModalHelper,
    RowCards,
    Tag,
    TrashIcon,
    Typography,
    displayMostRecentDate,
    formatDisplayName,
    getAccountUsers,
    openNotificationWithIcon,
    separateWithBullet,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useModal
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useMemo, useState } from "react";
import { ViewWrapper } from "../../../components";
import { AccountLang } from "../../../lang/AccountLang";
import { createApiKey, deleteApiKey, getApiKeys } from "../../../services";
import { AddApiKeyModal } from "./components/AddApiKeyModal";

const MAX_API_KEY_COUNT = 20;

export default function ApiKeysView(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { selectedAccount: account } = useAuthContext();
    const hasRightWRITE = useHasAccountRights(AccountRights.WRITE);
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [apiKeys, setApiKeys] = useState<AccountApiKey[]>([]);
    const { isOpen, doOpen, doClose } = useModal();

    const isApiKeyCreationEnabled = useMemo(() => apiKeys.length < MAX_API_KEY_COUNT, [apiKeys]);

    useEffect(() => {
        if (account?.id) {
            return getApiKeys(account.id, setApiKeys);
        }
        return () => {};
    }, [account?.id]);

    useEffect(() => getAccountUsers(account, setAccountUsers), [account]);

    if (!account) {
        return <ContentLoader />;
    }

    async function onApiKeyAddConfirm(data: Pick<AccountApiKey, "name" | "environment">) {
        if (!account) {
            return;
        }
        setIsGenerating(true);
        try {
            const newApiKey = await createApiKey(account.id, data.name, data.environment);
            ModalHelper.Modal({
                title: lang.accountSettings.apiKeysView.newApiKeyTitle,
                children: (
                    <div className="flex flex-col gap-4 p-4">
                        <Alert.Warning message={lang.accountSettings.apiKeysView.recapDescription} />
                        <div className="text-center font-bold">
                            <Typography.Text copyable>{newApiKey.hash}</Typography.Text>
                        </div>
                    </div>
                ),
                lang
            });
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
        } finally {
            setIsGenerating(false);
        }
    }

    function openDeleteConfirmationModal(apiKey: AccountApiKey): void {
        if (!account) {
            return;
        }
        ModalHelper.Confirm({
            title: lang.accountSettings.apiKeysView.deleteApiKeyTitle,
            children: lang.accountSettings.apiKeysView.deleteConfirmation(apiKey.name),
            onConfirm: async () => {
                try {
                    setIsWorking(true);
                    await deleteApiKey(account.id, apiKey.id);
                } catch (error) {
                    openNotificationWithIcon({ type: "error", description: lang.shared.failMessage });
                } finally {
                    setIsWorking(false);
                }
            },
            lang
        });
    }

    return (
        <ViewWrapper title={lang.accountSettings.apiKeysView.title}>
            {hasRightWRITE && (
                <div className="ml-auto">
                    <AddApiKeyModal isOpen={isOpen} doClose={doClose} onConfirm={onApiKeyAddConfirm} />
                    <Button
                        icon={AddIcon}
                        onClick={doOpen}
                        isLoading={isGenerating}
                        isDisabled={isWorking || !isApiKeyCreationEnabled}
                        children={lang.accountSettings.apiKeysView.newAccessToken}
                    />
                </div>
            )}
            {isApiKeyCreationEnabled ? (
                <Alert.Info message={lang.accountSettings.apiKeysView.description} />
            ) : (
                <Alert.Warning message={lang.accountSettings.apiKeysView.maxReached} />
            )}

            <List
                list={apiKeys}
                render={apiKey => {
                    const isForProduction = apiKey.environment === AccountApiKey.Environment.PRODUCTION;
                    const actions = hasRightWRITE
                        ? [
                              {
                                  icon: TrashIcon,
                                  "data-id": "remove-api-key",
                                  onClick: () => openDeleteConfirmationModal(apiKey),
                                  label: lang.shared.delete,
                                  danger: true
                              }
                          ]
                        : [];
                    const createdBy = accountUsers.find(({ id }) => id === apiKey.createdByUID);
                    return (
                        <RowCards.WithActions key={apiKey.id} icon={<KeyIcon size="lg" />} actions={actions} color={isForProduction ? "green" : "orange"}>
                            <div className="flex-1">
                                <div>{apiKey.name}</div>
                                <div className="text-grey-primary">
                                    {[
                                        displayMostRecentDate({ createdDate: apiKey.creationDate }, lang, true),
                                        createdBy ? [formatDisplayName(createdBy)] : []
                                    ].map(separateWithBullet)}
                                </div>
                            </div>
                            <Tag message={apiKey.environment.toUpperCase()} type={isForProduction ? "success" : "warning"} />
                        </RowCards.WithActions>
                    );
                }}
                emptyText={lang.accountSettings.apiKeysView.noResultFound}
                isVirtualized
            />
        </ViewWrapper>
    );
}
