import { ReactNode, Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { LoadingIcon, openNotificationWithIcon } from "../../../design-system";
import { useLang } from "../../../lang";
import { AppLayoutActions, useAppContext } from "../../contexts";
import { DropdownItem } from "../navigation/DropdownMenu";
import { MenuItem } from "../navigation/LeftNavBar";
import NavigationMenu from "../navigation/NavigationMenu";

type Props<TAppItem, TAppCode extends string> = {
    children: ReactNode;
    getMenuItems?: (appCode: TAppCode | undefined, selectedItem: TAppItem | null) => Promise<MenuItem[][]> | MenuItem[][];
    dropdownItems?: DropdownItem[];
    layout?: "logout-only" | "minimal" | "full";
    hideExpandButton?: boolean;
    isMenuDefaultExpanded?: boolean;
};

const AppLayout = forwardRef(
    <TAppItem extends { id: string }, TAppCode extends string>(
        { getMenuItems, ...props }: Props<TAppItem, TAppCode>,
        ref?: Ref<AppLayoutActions | null>
    ): JSX.Element => {
        const [menuItems, setMenuItems] = useState<MenuItem[][] | undefined>(undefined);
        const { selectedItem } = useAppContext<TAppItem, TAppCode>();
        const lang = useLang();
        const innerRef = useRef<AppLayoutActions | null>(null);
        const { appInfo } = useAppContext<TAppItem, TAppCode>();

        useImperativeHandle(ref, () => innerRef.current, []);

        useImperativeHandle(innerRef, () => ({
            async refreshMenu() {
                await refreshMenu();
            }
        }));

        useEffect(() => {
            void (async () => {
                await refreshMenu();
            })();
        }, [lang, selectedItem, appInfo.appCode]);

        async function refreshMenu() {
            try {
                if (selectedItem === undefined) {
                    setMenuItems([
                        [
                            {
                                key: "loading",
                                icon: LoadingIcon,
                                text: lang.shared.loading,
                                href: "#"
                            }
                        ]
                    ]);
                } else {
                    setMenuItems(await getMenuItems?.(appInfo.appCode, selectedItem));
                }
            } catch (e) {
                openNotificationWithIcon({ type: "error", message: lang.menu.failedToGetMenuItems });
            }
        }

        return (
            <div data-id="logged-page" className="h-screen">
                <NavigationMenu {...props} menuItems={menuItems} layoutActions={innerRef.current} />
            </div>
        );
    }
) as <TAppItem extends { id: string }, TAppCode extends string>(p: Props<TAppItem, TAppCode> & { ref?: Ref<AppLayoutActions> }) => JSX.Element;

export default AppLayout;
