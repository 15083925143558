import { createContext, useContext, useMemo } from "react";

export interface AppLayoutActions {
    refreshMenu(): void;
}

interface LayoutProps {
    contentHeight: number | undefined;
    layoutActions?: AppLayoutActions | null;
}

const Context = createContext<LayoutProps>({} as LayoutProps);

export function LayoutContextProvider({ children, value }: { children: React.ReactNode; value: LayoutProps }) {
    const exposed = useMemo((): LayoutProps => value, [value]);
    return <Context.Provider value={exposed} children={children} />;
}

export function useLayoutContext(): LayoutProps {
    return useContext(Context);
}
