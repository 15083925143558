import { de } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../AccountLang";

/* eslint max-len: 0 */
const deLang: AccountLang = {
    ...de,
    account: {
        apps: {
            title: "Apps",
            plan: "Plan",
            payment: "Zahlungsmethode"
        }
    },
    invitation: {
        invitationNotFound: "Einladung nicht gefunden", // to review
        wrongMatchingEmail: "Einladung stimmt nicht mit Ihrer E-Mail überein", // to review
        invitationAlreadyAccepted: "Einladung bereits angenommen", // to review
        accountNoLongerExists: "Organisation existiert nicht mehr", // to review
        invitationRejected: "Einladung abgelehnt", // to review
        invitationCanceled: "Einladung storniert", // to review
        missingData: "Einige Daten fehlen", // to review
        unknownCode: "Unbekannter Code" // to review
    },
    authentication: {
        login: "Anmeldung",
        rememberMe: "Erinnere mich",
        forgotPassword: "Passwort vergessen?",
        resetPassword: "Setzen Sie Ihr Passwort zurück",
        resetPasswordRetry: "Setzen Sie Ihr Passwort erneut zurück",
        recoverPasswordInstructions:
            "Bitte geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen eine Anleitung zum Zurücksetzen Ihres Passworts zusenden können.",
        resetPasswordInstructions: "Bitte wählen Sie ein neues Passwort.",
        resetPasswordSuccessMessage: "Passwort erfolgreich zurückgesetzt",
        verifyEmailSuccessMessage: "Ihre E-Mail wurde überprüft",
        notRegistered: "Noch nicht registriert?",
        logout: "Abmelden",
        errors: {
            "166": "Falsche Anmeldedaten, E-Mail und Passwort stimmen nicht überein.",
            loginFailed: "Anmeldung fehlgeschlagen."
        },
        loginFailedErrorMessage: "Anmeldung fehlgeschlagen.",
        tryAgain: "Versuchen Sie es später noch einmal. Es wurde bereits eine E-Mail gesendet.", // to review
        actionModeErrorMessage: "Ungültiger Modus",
        oobCodeErrorMessage: "Ihre Anfrage ist abgelaufen oder der Link wurde bereits verwendet",
        resetPasswordErrorMessage: "Passwort kann nicht zurückgesetzt werden",
        resetPasswordInfoMessage: "Wenn Sie ein Konto auf der Plattform haben, gehen Sie zu Ihrem E-Mail-Posteingang, um Ihr Passwort zurückzusetzen", // to review
        or: "Oder" // to review
    },
    homePage: {
        title: "Welche Aktion möchten Sie durchführen?",
        manageAccountSettings: "Organisationseinstellungen verwalten" // to review
    },
    domain: {
        title: "Domain", // to review
        failedToGetDomain: "Beim Abrufen der Domain ist ein Fehler aufgetreten" // to review
    },
    organisations: {
        title: "Organisationen", // to review
        searchOrganisation: "Suche nach Organisationen", // to review
        noOrganisationFound: "Keine Organisation auf dieser Domain gefunden", // to review
        viewOrganisation: "Organisation anzeigen", // to review
        creationDate: "Erstellungsdatum", // to review
        registeredDate: "Registrierungsdatum", // to review
        users: "Benutzer" // to review
    },
    sharedSettings: {
        noResultsSearch: "Es wurden keine Mitglieder für Ihre Anfrage gefunden.", // to review
        adminRole: "Admin", // to review
        representativeRole: "Vertreter", // to review
        contributorRole: "Mitwirkender", // to review
        readOnlyRole: "Schreibgeschützt" // to review
    },
    profileSettings: {
        warning: {
            emailNotVerified: "Ihre E-Mail Adresse wurde noch nicht bestätigt."
        },
        title: {
            personalAccount: "Persönliche Kontoeinstellungen"
        },
        menu: {
            profile: "Profil", // to review
            preferences: "Präferenzen",
            companyAccounts: "Meine Organisationen" // to review
        },
        form: {
            birthdate: "Geburtsdatum",
            cellPhone: "Mobiltelefon",
            email: "E-Mail",
            currentPassword: "Aktuelles Passwort", // to review
            firstName: "Vorname",
            lastName: "Nachname",
            nationality: "Nationalität",
            personalInformation: "Persönliche Informationen",
            preferences: "Präferenzen",
            preferredLanguage: "Bevorzugte Sprache"
        },
        companyAccounts: {
            // to review the entire section
            title: "Organisationen",
            whatIs: "Was ist ein Organisation ?",
            definition: "Ein Organisation ist das Unternehmen, dem man angehört oder das man vertritt.",
            newAccount: "Neues Organisation",
            search: "Suche nach Unternehmensnamen",
            leave: "Verlassen Sie",
            leaveAccount: companyName => `Organisation verlassen ${companyName}?`,
            leaveAccountDescription:
                companyName => `Bestätigen Sie, dass Sie das Organisation "${companyName}" verlassen möchten? Sie werden nicht mehr in der Lage sein
            auf Informationen zu diesem Organisation zugreifen können.`,
            leaveAccountNonAdminDescription:
                "Eine neue Einladung zu diesem Organisation ist erforderlich, wenn Sie diesem Firmenkonto erneut beitreten möchten.",
            leaveAccountAdminDescription: `Da Sie der einzige "Admin"-Benutzer dieses Organisation sind, können Sie das Organisation entweder vollständig für alle Benutzer löschen oder die
            alle Benutzer löschen oder die Admin-Rolle einem bestehenden Benutzer zuweisen`,
            newAdminToAssign: "Neuen Admin zuweisen",
            doNotAssignNewAdmin: "Keinen neuen Admin zuweisen",
            deleteAccount: "Organisation löschen",
            deleteAccountConfirmation: companyName => `Sind Sie sicher, dass Sie das Organisation löschen möchten "${companyName}"?`, // to review
            deleteAccountConfirmationDescription: "Alle Daten und Nutzer, die mit diesem Organisation in Verbindung stehen, werden dauerhaft entfernt.", // to review
            acceptInvitation: "Akzeptieren",
            rejectInvitation: "Ablehnen",
            invitedBy: senderName => `Eingeladen von ${senderName}`,
            failedToJoinAccount: "Beitritt zum Organisation fehlgeschlagen",
            failedToRejectInvitation: "Ablehnung der Einladung fehlgeschlagen" // to review
        }
    },
    accountSettings: {
        title: {
            organisationAccount: "Organisation Einstellungen"
        },
        rightContent: {
            switchTo: "Zu einem anderen Organisation wechseln"
        },
        menu: {
            organisation: "Organisation", // to review
            generalInformation: "Allgemeine Informationen", // to review
            userAccess: "Benutzerzugang",
            parties: "Teile", // to review
            billingAndSubscriptions: "Abrechnung & Abonnements",
            git: "Git",
            apiKeys: "API-Schlüssel" // to review
        },
        userAccessView: {
            name: "Benutzerzugang",
            whatIs: "Was ist ein Benutzer ?",
            definition:
                "Ein Benutzer ist ein direkter Mitarbeiter Ihres Unternehmens (z. B. ein Angestellter oder ein gesetzlicher Vertreter). Wenn Sie ihm eine Einladung schicken, stellen Sie sicher, dass Sie die entsprechenden Benutzerrechte festlegen.",
            invitations: "Einladungen", // to review
            changeRole: "Rolle ändern",
            removeFromAccount: "Vom Organisation entfernen",
            removeFromAccountConfirmationTitle: user => `Bestätigen Sie die Entfernung von Benutzer ${user}`,
            removeFromAccountConfirmationContent: (user, account) => `Dies entfernt Benutzer ${user} vom Organisation ${account}`,
            inviteUser: {
                name: "Benutzer einladen",
                tooltip: "Für den Versand einer Einladung ist die Rolle eines Administrators erforderlich.",
                emailPlaceholder: "E-Mail Adresse für die Einladung",
                rolePlaceholder: "Rolle",
                errors: {
                    alreadyInAccountErrorMessage: "Dieser Benutzer ist bereits Teil dieses Organisation.", // to review
                    alreadyInvitedErrorMessage: "Dieser Benutzer hat bereits eine Einladung erhalten." // to review
                }
            },
            invitePending: "Einladung ausstehend...",
            sendInvite: "Senden",
            resendInvite: "Einladung erneut senden", // to review
            cancelInvitation: "Einladung stornieren",
            search: "Suche nach E-Mail oder Name",
            findMember: "Mitglied finden",
            changeRoleTitle: user => `Rolle für ${user} ändern?`,
            selectRole: "Rolle auswählen",
            adminRoleDescription: "Verfügt über vollen administrativen Zugriff. Kann Benutzer einladen und entfernen und Zahlungen vornehmen",
            representativeRoleDescription:
                "Kann Informationen und Daten zu allen über dieses Organisation zugänglichen Diensten einladen und dazu beitragen, diese bereitzustellen.",
            contributorRoleDescription: "Kann dazu beitragen, Informationen und Daten für alle über dieses Organisation zugänglichen Dienste bereitzustellen.",
            readOnlyRoleDescription: "Kann alle Daten der Dienste in einem Nur-Lese-Modus anzeigen",
            changeRoleSuccessMessage: "Rolle erfolgreich aktualisiert",
            changeRoleErrorMessage: "Aktualisierung der Rolle fehlgeschlagen"
        },
        billingView: {
            plansAndSubscriptionsTitle: "Pläne und Abonnements", // to review
            billingInformationTitle: "Informationen zur Rechnungsstellung", // to review
            updateSubscription: "Abonnement aktualisieren", // to review
            updateSubscriptions: "Abonnement(s) aktualisieren", // to review
            noPlanOrSubscription: "Ihr Organisation hat derzeit keine aktiven Pläne oder Abonnements.", // to review
            invoice: {
                failed: "Beim Abrufen von Rechnungen ist ein Fehler aufgetreten", // to review
                invoiceNumber: "Rechnungsnummer", // to review
                status: {
                    name: "Status", // to review
                    draft: "Entwurf", // to review
                    void: "Leere", // to review
                    uncollectible: "Uneinbringlich", // to review
                    open: "Offen", // to review
                    paid: "Bezahlt" // to review
                },
                date: "Datum", // to review
                amount: "Menge", // to review
                label: {
                    "1": "Rechnung", // to review
                    plural: () => "Rechnungen" // to review
                },
                product: "Produkt", // to review
                failedToDownloadInvoice: "Beim Herunterladen der Rechnung ist ein Fehler aufgetreten" // to review
            },
            subscription: {
                failed: "Beim Abrufen von Abonnements ist ein Fehler aufgetreten", // to review
                status: {
                    name: "Status", // to review
                    active: "Aktiv", // to review
                    canceled: "Abgesagt", // to review
                    incomplete_expired: "Abgelaufen", // to review
                    past_due: "Überfällig" // to review
                },
                date: "Datum", // to review
                amount: "Menge",
                label: {
                    "1": "Abonnement", // to review
                    plural: () => "Abonnements" // to review
                },
                canceledAt: "Abgesagt in" // to review
            },
            paymentMethods: {
                title: "Zahlungsmethoden",
                expirationDate: "Verfallsdatum",
                deletePaymentMethod: "Zahlungsmethode löschen",
                deletePaymentMethodConfirmation: "Sind Sie sicher, dass Sie diese Zahlungsmethode löschen möchten?",
                deletePaymentMethodFailed: "Beim Löschen der Zahlungsmethode ist ein Fehler aufgetreten",
                listPaymentMethodFailed: "Beim Abrufen der Zahlungsmethoden ist ein Fehler aufgetreten"
            }
        },
        partiesView: {
            name: "Teile", // to review
            whatIs: "Was ist eine Teil?", // to review
            definition:
                "Eine Teil ist ein Lieferant oder ein Begünstigter, mit dem Sie bei Prüfungen/Treuhandgeschäften zusammenarbeiten werden. Eine eingeladene Teil kann gemeinsame Informationen über Prüfungen/Treuhänderschaften sowie Ihren Firmennamen, Ihre Adresse und Ihre Registrierungsnummer einsehen.",
            invitations: "Einladungen", // to review
            sentYouAPartyRequest: companyName => `${companyName} möchte eine Ihrer Teile sein`, // to review
            isNowYourParty: companyName => `${companyName} ist nun ein Teil von Ihnen`, // to review
            confirmReject: companyName => `Sind Sie sicher, dass Sie die Einladung von ${companyName} ablehnen wollen?`, // to review
            linkPartyAccount: "Ein Spiel verknüpfen", // to review
            startWorkingTogether: partnerCompanyName =>
                `Bitte bestätigen Sie, dass Sie damit einverstanden sind, als neuer Teil von “${partnerCompanyName}” aufgenommen zu werden. Als Teil können Sie gemeinsame Informationen über Prüfungen und/oder Treuhandkonten einsehen und den Namen, die Adresse und die Registrierungsnummer des jeweils anderen Unternehmens sehen.`, // to review
            selectAnotherAccount: "Wenn Sie ein anderes Organisation verknüpfen möchten, wählen Sie unten das richtige Organisation aus", // to review
            noPartyYet: "Sie haben noch keine Einladung zur Teilnahme an der Veranstaltung erhalten.", // to review
            invitationRejected: "Einladung abgelehnt", // to review
            invitationCancelled: "Einladung storniert", // to review
            errorOccured: "Beim Abrufen des Teils ist ein Fehler aufgetreten", // to review
            invitationAlreadySent: "Einladung bereits verschickt", // to review
            reportToAdmin:
                "Sie verfügen nicht über die notwendigen Rechte, um Einladungen von anderen Teile einzuladen oder anzunehmen. Bitte wenden Sie sich an den Administrator des Organisationen" // to review
        },
        gitView: {
            title: "Verbindungen zur Versionskontrolle", // to review
            newConnection: "Neue Verbindung hinzufügen", // to review
            installingConnection: "Installation der Verbindung...", // to review
            deleteConnection: "Verbindung löschen", // to review
            deleteConnectionConfirmation: "Sind Sie sicher, dass Sie diese Git-Verbindung löschen wollen?", // to review
            selectConnection: "Verbindungsquelle auswählen:", // to review
            featureComingSoon: "Bald verfügbar!", // to review
            lastConnectionDate: "Verbunden", // to review
            noResultsSearch: "Keine Git-Verbindung gefunden", // to review
            installationConfirmationMessage: provider =>
                `Wenn Sie auf "Bestätigen" klicken, werden Sie auf die Website von ${provider} weitergeleitet, um die Installation abzuschließen.`, // to review
            deletionConfirmationMessage: provider =>
                `Wenn Sie auf "Bestätigen" klicken, werden Sie auf die Website von ${provider} weitergeleitet, um die Verbindung zu löschen.`, // to review
            installed: "Installiert", // to review
            disconnected: "Getrennt", // to review
            fetching: "Code-Verzeichnisse abrufen", // to review
            fetchFailed: "Codeverzeichnisse konnten nicht abgerufen werden", // to review
            lastConnected: date => `Letzte Verbindung ${date}` // to review
        },
        apiKeysView: {
            title: "API-Zugangsschlüssel", // to review
            newAccessToken: "Neues Zugangstoken generieren", // to review
            description: "Token, die Sie generiert haben und die für den Zugriff auf die Vaultinum-API verwendet werden können", // to review
            newApiKeyTitle: "Neues API-Zugangs-Token", // to review
            nameFieldPlaceholder: "Api-Schlüssel-Name...", // to review
            keyEnvironments: {
                sandbox: "Sandkastenschlüssel", // to review
                production: "Produktionsschlüssel" // to review
            },
            generate: "Erzeugen Sie", // to review
            recapDescription: "Warnung: Speichern Sie diesen Schlüssel auf, denn Sie werden ihn nur jetzt sehen.", // to review
            noResultFound: "Kein API-Schlüssel gefunden.", // to review
            deleteApiKeyTitle: "API-Zugangs-Token löschen", // to review
            deleteConfirmation: name => `Sind Sie sicher, dass Sie das API-Zugangs-Token ${name} löschen möchten?`, // to review
            maxReached: "Sie haben die maximale Anzahl von API-Schlüsseln erreicht." // to review
        }
    },
    onboarding: {
        ...de.onboarding,
        verifyEmail: {
            sent: email => `Wir haben Ihnen eine Aktivierung-E-Mail geschickt an ${email}.`,
            confirm: "Bitte überprüfen Sie Ihre E-Mail, um fortzufahren." // todo to review
        },
        userProfile: {
            title: "Ihre Profilinformatione", // to review
            successOnSave: "Ihre Profilinformationen wurden erfolgreich gespeichert.",
            errorOnSave: "Ihre Profilinformationen konnten nicht gespeichert werden."
        },
        invitations: {
            // to review all section
            title: "Ihre Einladungen",
            description: {
                "1": "Sie sind bereits von einer Organisation eingeladen worden. Wählen Sie diese aus, wenn Sie beitreten möchten:",
                plural: count => `Sie wurden bereits von ${count} Organisationen eingeladen. Wählen Sie die Organisationen aus, denen Sie beitreten möchten:`
            },
            joinOrganisation: {
                "0": "Ignorieren und meine Organisation einrichten",
                "1": "Dieser Organisation beitreten",
                plural: count => `Treten Sie diesen ${count} Organisationen bei`
            },
            successOnJoin: {
                "1": "Sie haben sich einer Organisation erfolgreich angeschlossen.",
                plural: count => `Sie haben ${count} Organisationen erfolgreich beigetreten.`
            },
            errorOnJoin: {
                "1": "Der Beitritt zu einer Organisation ist fehlgeschlagen.",
                plural: count => `Beitritt zu ${count} Organisationen fehlgeschlagen.`
            }
        },
        account: {
            createAs: {
                title: "Erstellen als", // to review
                organisationMember: {
                    title: "Organisationsmitglied", // to review
                    description: "Erstellen und verwalten Sie Ihre Organisation."
                },
                representative: {
                    title: "Bevollmächtigter",
                    description: "Erstellen und verwalten Sie ein Organisation im Namen eines Ihrer Kunden"
                }
            },
            accountInfo: {
                title: isRepresentative => `Über ${isRepresentative ? "die Organisation Ihres Kunden" : "Ihr Organisation"}` // to review
            },
            collaborate: {
                // to review the entire section
                title: isRepresentative => (isRepresentative ? "Kundenkontakt" : "Zusammenarbeit"), // to review
                description: isRepresentative => {
                    // to review
                    if (isRepresentative) {
                        return "Bitte geben Sie die Kontakt-E-Mail Ihres Kunden an. Er kann diesem Organisation als Administrator beitreten, wodurch er die volle Kontrolle über dieses neue Organisation erhält.";
                    }
                    return "Laden Sie Mitarbeiter ein, mit Ihnen zu arbeiten. Sie können diesem Organisation beitreten und erhalten die Rechte, Daten zu bearbeiten und hochzuladen. Sie können ihre Rollen später auf der Seite mit den Kontoeinstellungen jederzeit ändern und weitere Einladungen versenden.";
                },
                collaboratorEmail: isRepresentative => `E-Mail des ${isRepresentative ? "Kundenkontakts" : "Mitarbeiters"}`, // to review
                addAnotherCollaborator: isRepresentative => `Einen weiteren ${isRepresentative ? "Kundenkontakt" : "Mitarbeiter"} hinzufügen`, // to review
                sendInvitation: {
                    // to review
                    "1": "Einladung senden per Email",
                    plural: () => "Einladungen versenden per Email"
                }
            },
            successOnSave: isRepresentative => `${isRepresentative ? "Das Organisation Ihres Kunden" : "Ihr Organisation"} wurde erfolgreich erstellt.`, // to review
            errorOnSave: isRepresentative => `${isRepresentative ? "Das Organisation Ihres Kunden" : "Ihr Organisation"} konnte nicht erstellt werden.` // to review
        },
        moreInfoLink: "Mehr darüber, warum und wie wir Ihre Daten verwenden."
    }
};
export default deLang;
