/**
 * The aim of this service is to abstract the Firebase services to ensure proper initialisation and limit impact in case of api changes.
 * @module services/firebase
 */
import { getApp, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
    connectDatabaseEmulator,
    getDatabase,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    onDisconnect,
    onValue,
    child as rDBChild,
    get as rDBGet,
    orderByChild as rDBOrderByChild,
    query as rDBQuery,
    ref as rDBRef,
    serverTimestamp as rDBServerTimestamp,
    startAfter as rDBStartAfter,
    startAt as rDBStartAt
} from "firebase/database";
import { DocumentData, DocumentSnapshot, Query, connectFirestoreEmulator, getDocs, getFirestore, initializeFirestore } from "firebase/firestore";
import { connectStorageEmulator, getBlob, getDownloadURL, getStorage, ref as storageRef } from "firebase/storage";
import fileDownload from "js-file-download";
import { chunk } from "lodash";

export const MAX_IN_OR_QUERIES = 10;

export async function getDocsByBatch<T>(ids: string[], query: (ids: string[]) => Query<DocumentData>, docToT: (snapshot: DocumentSnapshot) => T): Promise<T[]> {
    return (await Promise.all(chunk(ids, MAX_IN_OR_QUERIES).map(async batch => (await getDocs(query(batch))).docs.map(docToT)))).flat();
}

export function initFirebase(config: string): void {
    const app = initializeApp(JSON.parse(config));
    initializeFirestore(app, { experimentalAutoDetectLongPolling: true });
}

export function useAuthEmulator(emulatorHost: string): void {
    connectAuthEmulator(getAuth(), emulatorHost);
}

export function useFirestoreEmulator(emulatorHost: string, port: number): void {
    connectFirestoreEmulator(getFirestore(), emulatorHost, port);
}

export function useStorageEmulator(emulatorHost: string, port: number): void {
    connectStorageEmulator(getStorage(), emulatorHost, port);
}

export function useDatabaseEmulator(emulatorHost: string, port: number): void {
    connectDatabaseEmulator(getDatabase(), emulatorHost, port);
}

export async function downloadStorageFile(path: string, filename: string, mimeType?: string): Promise<void> {
    const data = await getBlob(storageRef(getStorage(getApp()), path));
    return fileDownload(data, encodeURIComponent(filename), mimeType);
}

export async function getDownloadURLFromStorage(path: string): Promise<string> {
    return getDownloadURL(storageRef(getStorage(getApp()), path));
}

export { getApp } from "firebase/app";
export {
    GoogleAuthProvider,
    OAuthProvider,
    applyActionCode,
    browserLocalPersistence,
    browserSessionPersistence,
    createUserWithEmailAndPassword,
    getAuth,
    linkWithCredential,
    sendEmailVerification,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
    signInWithRedirect,
    verifyPasswordResetCode
} from "firebase/auth";
export type { Persistence, User, UserCredential } from "firebase/auth";
export type { DataSnapshot, DatabaseReference, Query as RDBQuery } from "firebase/database";
export * from "firebase/firestore";
export { getBlob, getBytes, getDownloadURL, getMetadata, getStorage, list, ref, uploadBytes } from "firebase/storage";
export type { UploadResult } from "firebase/storage";
// Cannot use namespace, export as const
export const RDB = {
    child: rDBChild,
    getDatabase,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    onDisconnect,
    get: rDBGet,
    onValue,
    query: rDBQuery,
    ref: rDBRef,
    serverTimestamp: rDBServerTimestamp,
    startAt: rDBStartAt,
    startAfter: rDBStartAfter,
    orderByChild: rDBOrderByChild
};
