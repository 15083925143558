import { AccountInvitation } from "@vaultinum/vaultinum-api";
import {
    formatDisplayName,
    getAccount,
    getAccountUser,
    List,
    openNotificationWithIcon,
    plural,
    RowCards,
    Skeleton,
    Tag,
    useForm,
    useLang,
    yup
} from "@vaultinum/vaultinum-sdk";
import { partition } from "lodash";
import { useEffect, useState } from "react";
import { AccountLang } from "../../../lang/AccountLang";
import { acceptInvitation, formatRightsToAccountRole, InvitationsFields } from "../../../services";
import { OnboardingContext } from "./onboardingMachine";
import { Step } from "./Step";

function Invitation({
    invitation,
    selectedIds,
    onValueChange,
    isDisabled
}: {
    invitation: AccountInvitation;
    selectedIds: string[];
    onValueChange: (id: string) => void;
    isDisabled: boolean;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const [companyName, setCompanyName] = useState<string>("‎"); // Default to an empty caracter as skeleton to avoid the card to resize when the company name is fetched
    const [senderName, setSenderName] = useState<string | null>();

    useEffect(() => {
        void (async () => {
            const account = await getAccount(invitation.accountId);
            if (!account) {
                return;
            }
            setCompanyName(account.companyName.trim() ?? "‎");
            const sender = await getAccountUser(account, invitation.sentByUID);
            setSenderName(sender ? formatDisplayName(sender) : null);
        })();
    }, [invitation.accountId, invitation.sentByUID]);

    return (
        <RowCards.WithCheckbox
            data-id="account-invitation"
            title={{ text: companyName }}
            children={senderName ? lang.profileSettings.companyAccounts.invitedBy(senderName) : <Skeleton className="h-5 w-1/3" />}
            isDisabled={isDisabled}
            checkbox={{
                id: invitation.accountId,
                isSelected: selectedIds.includes(invitation.accountId),
                onValueChange
            }}
            rightChildren={<Tag message={formatRightsToAccountRole(lang, invitation.rights)} />}
        />
    );
}

export default function InvitationsStep({
    context,
    onChange,
    doBack,
    doNext
}: {
    context: OnboardingContext;
    onChange: (update: InvitationsFields) => void;
    doBack: () => void;
    doNext: (acceptedInvitationsCount: number) => void;
}): JSX.Element {
    const lang = useLang<AccountLang>();
    const [selectedIds, setSelectedIds] = useState<string[]>(context.invitationsFields.selectedInvitations.map(({ accountId }) => accountId));
    const [isLoading, setIsLoading] = useState(false);

    function toggleCheckbox(accountId: string): void {
        setSelectedIds(prev => {
            const newSelectedIds = prev.includes(accountId) ? prev.filter(prevId => prevId !== accountId) : [...prev, accountId];
            onChange({ selectedInvitations: (context.invitations || []).filter(invitation => newSelectedIds.includes(invitation.accountId)) });
            return newSelectedIds;
        });
    }

    const schema = yup.object({
        selectedInvitations: yup.array().of(yup.object().required()).required()
    } satisfies Record<keyof InvitationsFields, yup.AnySchema>);

    const form = useForm<InvitationsFields>({
        schema,
        defaultValues: context.invitationsFields,
        mode: "onChange"
    });

    async function onSubmit() {
        try {
            setIsLoading(true);
            const results = await Promise.allSettled(context.invitationsFields.selectedInvitations.map(acceptInvitation));
            const [fulfilled, rejected] = partition(results, result => result.status === "fulfilled");
            if (rejected.length) {
                const message = plural(lang.onboarding.invitations.errorOnJoin, rejected.length);
                if (rejected.length === context.invitationsFields.selectedInvitations.length) {
                    throw new Error(message);
                }
                openNotificationWithIcon({ type: "error", description: message });
            }
            doNext(fulfilled.length);
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: error.message });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Step
            title={lang.onboarding.invitations.title}
            doBack={doBack}
            onSubmit={onSubmit}
            submitText={plural(lang.onboarding.invitations.joinOrganisation, selectedIds.length)}
            stepKey="invitations-step"
            form={form}
            isCard={false}
        >
            <h4 data-id="description">{plural(lang.onboarding.invitations.description, context.invitations?.length)}</h4>
            <List
                list={context.invitations}
                render={invitation => (
                    <Invitation
                        key={invitation.accountId}
                        invitation={invitation}
                        selectedIds={selectedIds}
                        onValueChange={toggleCheckbox}
                        isDisabled={isLoading}
                    />
                )}
            />
        </Step>
    );
}
