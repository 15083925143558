import {
    BrandLogo,
    Button,
    CardWithFeedback,
    Form,
    PasswordFields,
    UseFormReturn,
    confirmValidation,
    getAuth,
    getDomainLogo,
    passwordValidation,
    resetPassword,
    useForm,
    useLang,
    useUrlSearch,
    useWhiteLabelContext,
    verifyPasswordResetCode,
    yup
} from "@vaultinum/vaultinum-sdk";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FeedbackButton } from "../../components/FeedbackButton";
import { AccountLang } from "../../lang/AccountLang";
import { URL } from "../../services/routingService";

export default function ResetPasswordPage(): JSX.Element {
    const { oobCode } = useUrlSearch() as { oobCode?: string };
    const { whiteLabelDomain, domainLogo } = useWhiteLabelContext();
    const [email, setEmail] = useState<string>();
    const [loading, setLoading] = useState(true);
    const [errorTitle, setErrorMessage] = useState<string>();
    const [status, setStatus] = useState<"success" | "error" | undefined>();
    const lang = useLang<AccountLang>();
    const schema = yup.object({
        password: passwordValidation(lang),
        confirm: confirmValidation(lang)
    });
    const form = useForm<{ password: string; confirm: string }>({
        schema,
        mode: "onChange"
    });

    const {
        handleSubmit,
        watch,
        formState: { isValid },
        trigger
    } = form;

    const { password, confirm } = watch();

    useEffect(() => {
        // Needed to trigger confirm password match from first password field
        if (confirm) {
            void trigger("confirm");
        }
    }, [password, confirm, trigger]);

    const sendRecoverPasswordEmail = async ({ password: pwd }: { password: string }) => {
        if (!oobCode || !email) {
            setStatus("error");
            return;
        }
        try {
            setLoading(true);
            await resetPassword(email, pwd, oobCode);
            setStatus("success");
        } catch {
            setErrorMessage(lang.authentication.resetPasswordErrorMessage);
            setStatus("error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void (async function () {
            try {
                if (!oobCode) {
                    throw Error("oobCode missing");
                }
                const authenticatedEmail = await verifyPasswordResetCode(getAuth(), oobCode);
                setEmail(authenticatedEmail);
            } catch {
                setErrorMessage(lang.authentication.oobCodeErrorMessage);
                setStatus("error");
            }
            setLoading(false);
        })();
    }, [oobCode, lang]);

    return (
        <CardWithFeedback
            loading={loading && !email}
            extra={getDomainLogo(domainLogo, whiteLabelDomain, <BrandLogo />, "w-72")}
            title={lang.authentication.resetPassword}
            status={status}
            successTitle={lang.authentication.resetPasswordSuccessMessage}
            successExtra={<FeedbackButton label={lang.authentication.login} dest={URL.login} />}
            errorTitle={errorTitle}
            errorExtra={<FeedbackButton label={lang.authentication.resetPasswordRetry} dest={URL.forgotPassword} />}
        >
            <Form onSubmit={handleSubmit(sendRecoverPasswordEmail)}>
                <PasswordFields
                    form={form as unknown as UseFormReturn<{ password: string; confirm: string }>}
                    email={email ?? ""}
                    password={password}
                    working={loading}
                    lang={lang}
                />
                <div className="flex justify-between pt-4">
                    <Link to={URL.login}>{lang.shared.back}</Link>
                    <Button htmlType="submit" isLoading={loading} isDisabled={!isValid}>
                        {lang.authentication.resetPassword}
                    </Button>
                </div>
            </Form>
        </CardWithFeedback>
    );
}
