import classNames from "classnames";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AdaptiveLink, MenuIcon } from "../../../design-system";
import { useBrandContext, useWhiteLabelContext } from "../../contexts";
import "./HeaderNavbar.css";

export type HeaderMenuItemProps = {
    title: string;
    href?: string;
    children?: HeaderMenuItemProps[];
    isExternalLink?: boolean;
};

function isMatchingHref(pathname: string, href?: string): boolean {
    if (!href) {
        return false;
    }
    return pathname === href || pathname.startsWith(href.endsWith("/") ? href : `${href}/`);
}

function HeaderMenuItem({ menuItem, onClose }: { menuItem: HeaderMenuItemProps; onClose: () => void }): JSX.Element {
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const location = useLocation();

    const closeSubMenu = () => {
        setSubMenuOpen(false);
        onClose();
    };

    if (!menuItem.children) {
        return (
            <AdaptiveLink
                href={menuItem.href}
                onClick={onClose}
                routed={!menuItem.isExternalLink}
                className={classNames("menu-item", {
                    active: isMatchingHref(location.pathname, menuItem.href)
                })}
            >
                {menuItem.title}
            </AdaptiveLink>
        );
    }
    return (
        <div className="relative">
            <a
                onClick={() => setSubMenuOpen(state => !state)}
                className={classNames("menu-item flex h-16", {
                    active: isMatchingHref(location.pathname, menuItem.href)
                })}
            >
                {menuItem.title}
                <svg className="ml-4 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </a>
            <div
                className={classNames(
                    {
                        hidden: !subMenuOpen
                    },
                    "sub-menu left-0 top-16 w-full shadow-xl md:absolute"
                )}
            >
                <ul className="ml-0 flex list-none flex-col bg-primary">
                    {menuItem.children.map(subMenuItem => (
                        <li key={subMenuItem.title}>
                            <AdaptiveLink href={subMenuItem.href} onClick={closeSubMenu} routed className="block w-full p-4 hover:bg-secondary">
                                {subMenuItem.title}
                            </AdaptiveLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default function HeaderNavbar({
    menuItems,
    homeHref = "",
    isHomeLinkRouted,
    rightContent
}: {
    menuItems: HeaderMenuItemProps[];
    homeHref: string;
    isHomeLinkRouted?: boolean;
    rightContent?: React.ReactNode;
}): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const headerHeight = "h-12 md:h-16";
    const toggleMenu = () => setIsOpen(state => !state);
    const closeMenu = () => setIsOpen(false);
    const { domainLogo } = useWhiteLabelContext();
    const { logoPath } = useBrandContext();

    return (
        <>
            <header className={`fixed z-50 w-full text-lg shadow ${headerHeight} bg-primary`}>
                <nav className="flex h-full w-full items-center justify-between text-white">
                    <div className="header-skew flex h-full w-28 shrink-0 items-center bg-white py-0.5 pl-3 pr-6 md:w-40 md:pl-6 md:pr-12">
                        <AdaptiveLink href={homeHref} routed={isHomeLinkRouted}>
                            <img src={domainLogo ?? logoPath} alt="Brand logo" className="w-14 md:w-20" />
                        </AdaptiveLink>
                    </div>
                    {!!menuItems.length && (
                        <div className="ml-4 flex-1 py-2 md:hidden">
                            <MenuIcon onClick={toggleMenu} />
                        </div>
                    )}
                    <ul
                        className={classNames(
                            "absolute left-0 top-12 mb-0 ml-0 shrink-0 list-none bg-primary md:relative md:top-0 md:pl-5",
                            // I need to add "flex" and "md:flex" else the "hidden" takes over on wide screens
                            "flex w-full flex-col md:flex md:w-auto md:flex-row",
                            {
                                hidden: !isOpen
                            }
                        )}
                    >
                        {menuItems.map(menuItem => (
                            <li
                                key={menuItem.title}
                                className={classNames("mt-0 contents", {
                                    "nav-drop-down": menuItem.children !== undefined
                                })}
                                children={<HeaderMenuItem menuItem={menuItem} onClose={closeMenu} />}
                            />
                        ))}
                    </ul>
                    {rightContent}
                </nav>
            </header>
            {/* add a div with same height so top page content does not get hidden by header bar */}
            <div className={`flex-none ${headerHeight}`} />
        </>
    );
}
