import { Row, Table as TanstackTable } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import { Input } from "../Input";

export function SelectRowCheckbox<T>({ row, table }: { row: Row<T>; table: TanstackTable<T> }): JSX.Element {
    function onCheck(event: ChangeEvent<HTMLInputElement>): void {
        const isChecked = event.target.checked;

        // Select current row
        row.toggleSelected(isChecked, { selectChildren: true });

        // In case of subcomponents, we need to toggle their selection
        if (row.subRows.length) {
            row.subRows.forEach(subRow => subRow.toggleSelected(isChecked));
        }

        // In case this is a subrow, handle the parent state
        if (row.parentId) {
            const parentRow = table.getRow(row.parentId);
            if (parentRow) {
                if (!parentRow.getIsSomeSelected()) {
                    parentRow.toggleSelected(false, { selectChildren: false });
                } else if (parentRow.getIsAllSubRowsSelected()) {
                    parentRow.toggleSelected(true, { selectChildren: false });
                }
            }
        }
    }

    return (
        <div className="flex h-full items-center justify-center">
            <Input.Checkbox isIndeterminate={row.getIsSomeSelected()} onChange={onCheck} checked={row.getIsSelected() || row.getIsAllSubRowsSelected()} />
        </div>
    );
}

export function SelectAllHeader<T>({ table }: { table: TanstackTable<T> }): JSX.Element {
    return (
        <div className="flex w-full justify-center">
            <Input.Checkbox
                isIndeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
                checked={table.getIsAllRowsSelected()}
            />
        </div>
    );
}
