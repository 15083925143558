import { Skeleton } from "../Skeleton";

export namespace Skeletons {
    export function Row({ row }: { row: string[] }) {
        return (
            <div className="flex flex-1 items-center gap-5">
                {row.map(className => (
                    <Skeleton key={className} className={className} />
                ))}
            </div>
        );
    }

    export function Col({ col }: { col: string[] }) {
        return (
            <div className="flex flex-1 flex-col gap-2.5">
                {col.map((className, i) => (
                    <Skeleton key={i} className={className} />
                ))}
            </div>
        );
    }
}
