import { LanguageSupport } from "@codemirror/language";
import { linter, type Diagnostic } from "@codemirror/lint";
import CodeMirror, { EditorView, gutter, lineNumbers } from "@uiw/react-codemirror";
import { LANGUAGES_SUPPORT } from ".";
import { COLOR_SCHEME } from "../../referentials";

export type LineExtractSeverity = Diagnostic["severity"];

type MarkerOption = {
    fromChar: number; // starting char
    charCount?: number; // number of char to mark
    message?: string;
    severity?: LineExtractSeverity;
};

type LineOption = {
    sourceLine: number;
    sourceColumn?: number;
    marker?: MarkerOption;
};

const THEME = {
    ".cm-activeLine": {
        backgroundColor: "transparent"
    },
    ".cm-activeLineGutter": {
        backgroundColor: "transparent"
    },
    ".cm-gutters": {
        backgroundColor: COLOR_SCHEME.grey.extraLight,
        text: "black"
    },
    ".cm-lineNumbers .cm-gutterElement": {
        color: "black",
        fontWeight: "bold",
        minWidth: "50px",
        textAlign: "left"
    }
};

function getLanguageSupport(language?: keyof typeof LANGUAGES_SUPPORT): LanguageSupport | null {
    if (!language) {
        return null;
    }
    const languageSupport = LANGUAGES_SUPPORT[language];
    return typeof languageSupport === "function" ? languageSupport() : languageSupport;
}

function hasMarker(line: LineOption): line is Required<LineOption> {
    return !!line.marker;
}

function markValidationErrors(view: EditorView, lines: LineOption[]): Diagnostic[] {
    return lines.filter(hasMarker).map((line, index) => {
        const { fromChar, charCount, severity = "error", message = "" } = line.marker;

        const lineInfo = view.state.doc.line(index + 1);
        const startChar = lineInfo.from + (fromChar - 1);
        const endChar = startChar + Math.max(5, charCount ?? 1);

        return {
            from: startChar,
            to: endChar,
            severity,
            message
        };
    });
}

export type FileExtractProps = {
    extract: string;
    lines?: LineOption[];
    isSelected?: boolean;
    isFirst?: boolean;
    isLast?: boolean;
    currentIndex?: number;
    languageSupport?: keyof typeof LANGUAGES_SUPPORT;
};

export default function FileExtract({ extract, lines, languageSupport, isSelected, isFirst, isLast, currentIndex }: FileExtractProps): JSX.Element {
    const languageExtension = getLanguageSupport(languageSupport);
    const borderColor = isSelected ? COLOR_SCHEME.blue.light : COLOR_SCHEME.grey.light;
    const isSingle = !currentIndex && ((!isLast && !isFirst) || (isFirst && isLast));
    return (
        <div className="flex w-full flex-col rounded-md">
            <div className="w-full">
                <CodeMirror
                    value={extract}
                    readOnly
                    theme={EditorView.theme({
                        ...THEME,
                        ".cm-gutters": {
                            backgroundColor: isSelected ? COLOR_SCHEME.blue.extraLight : COLOR_SCHEME.grey.extraLight,
                            borderColor,
                            minWidth: "70px"
                        },
                        "&": {
                            border: `solid 1px ${borderColor}`,
                            backgroundColor: isSelected ? COLOR_SCHEME.blue.extraLight : COLOR_SCHEME.white.primary,
                            ...(isFirst && { borderRadius: "6px 6px 0 0" }),
                            ...(isLast && { borderRadius: "0 0 6px 6px" }),
                            ...(!isFirst && !isSingle && { borderWidth: "0 1px 1px 1px" }),
                            ...(isSingle && { borderRadius: "6px" })
                        }
                    })}
                    extensions={[
                        gutter({ class: "CodeMirror-lint-markers" }),
                        linter(view => markValidationErrors(view, lines ?? [])),
                        lineNumbers({
                            formatNumber: (lineNo: number) => {
                                const currentLine = lines?.[lineNo - 1];
                                const lineNumber = currentLine?.sourceLine?.toString() ?? String(lineNo);
                                if (currentLine?.sourceColumn) {
                                    return `${lineNumber}:${currentLine.sourceColumn}`;
                                }
                                return lineNumber;
                            }
                        }),
                        ...(languageExtension ? [languageExtension] : [])
                    ]}
                />
            </div>
        </div>
    );
}
