import {
    AccountIcon,
    addQueryParamsToUrl,
    CompanyIcon,
    CreditCardIcon,
    DomainsIcon,
    GithubIcon,
    KeyIcon,
    MenuItem,
    OverviewIcon,
    SettingsIcon,
    SubItemProps,
    ToggleIcon,
    UserIcon,
    UsersIcon
} from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../lang/AccountLang";
import { URL } from "../services";
import { isMenuItemEnabled } from "./menu.helper";

export enum MenuItemKeys {
    PROFILE = "profile",
    ACCOUNTS = "accounts",
    PREFERENCES = "preferences",
    ORGANISATION = "organisation",
    USERS = "users",
    BILLING = "billing",
    PARTIES = "parties",
    GIT = "git",
    API_KEYS = "apikeys"
}

function getProfileSubItems(lang: AccountLang): SubItemProps[] {
    return [
        {
            key: MenuItemKeys.PROFILE,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.PROFILE }),
            text: lang.profileSettings.form.personalInformation,
            icon: UserIcon
        },
        {
            key: MenuItemKeys.ACCOUNTS,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.ACCOUNTS }),
            text: lang.profileSettings.menu.companyAccounts,
            icon: CompanyIcon
        },
        {
            key: MenuItemKeys.PREFERENCES,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.PREFERENCES }),
            text: lang.profileSettings.menu.preferences,
            icon: ToggleIcon
        }
    ];
}

function getOrganisationSubItems(lang: AccountLang, isBillingMenuVisible?: boolean): SubItemProps[] {
    const subItems: SubItemProps[] = [
        {
            key: MenuItemKeys.ORGANISATION,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.ORGANISATION }),
            text: lang.accountSettings.menu.generalInformation,
            icon: CompanyIcon
        },
        {
            key: MenuItemKeys.USERS,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.USERS }),
            text: lang.accountSettings.menu.userAccess,
            icon: AccountIcon
        }
    ];

    if (isMenuItemEnabled(MenuItemKeys.BILLING, isBillingMenuVisible)) {
        subItems.push({
            key: MenuItemKeys.BILLING,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.BILLING }),
            text: lang.accountSettings.menu.billingAndSubscriptions,
            icon: CreditCardIcon
        });
    }

    if (isMenuItemEnabled(MenuItemKeys.PARTIES)) {
        subItems.push({
            key: MenuItemKeys.PARTIES,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.PARTIES }),
            text: lang.accountSettings.menu.parties,
            icon: UsersIcon
        });
    }

    if (isMenuItemEnabled(MenuItemKeys.GIT)) {
        subItems.push({
            key: MenuItemKeys.GIT,
            href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.GIT }),
            text: lang.accountSettings.menu.git,
            icon: GithubIcon
        });
    }

    subItems.push({
        key: MenuItemKeys.API_KEYS,
        href: addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.API_KEYS }),
        text: lang.accountSettings.menu.apiKeys,
        icon: KeyIcon
    });

    return subItems;
}

export function getMenuItems(lang: AccountLang, isBillingMenuVisible?: boolean, isDomainOwner?: boolean): MenuItem[][] {
    const menuItems: MenuItem[] = [
        {
            key: "overview",
            text: lang.account.apps.title,
            icon: OverviewIcon,
            href: "/app"
        }
    ];

    if (isDomainOwner) {
        menuItems.push({
            key: "domain",
            text: lang.organisations.title,
            icon: DomainsIcon,
            href: URL.domainOrganisations.index
        });
    }

    menuItems.push({
        key: "settings",
        text: lang.settingsMenu.settings,
        icon: SettingsIcon,
        href: URL.settings.index,
        subMenuItems: [
            {
                text: lang.profileSettings.menu.profile,
                items: getProfileSubItems(lang)
            },
            {
                text: lang.accountSettings.menu.organisation,
                items: getOrganisationSubItems(lang, isBillingMenuVisible)
            }
        ]
    });

    return [menuItems];
}
