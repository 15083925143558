import { AccountRights } from "@vaultinum/vaultinum-api";
import {
    AppLayout,
    ContentLoader,
    addQueryParamsToUrl,
    isDomainOwner,
    useAuthContext,
    useHasAccountRights,
    useLang,
    useUrlSearch,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useNavigate } from "react-router-dom";
import { MenuItemKeys, getMenuItems } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import { URL } from "../../../services";
import ApiKeysView from "./ApiKeysView";
import BillingSubscriptionView from "./BillingSubscriptionView";
import CompanyAccounts from "./CompanyAccounts";
import CompanyInformationView from "./CompanyInformationView";
import GitConnectionsView from "./GitConnectionsView";
import PartnerView from "./PartnerView";
import PartnersView from "./PartnersView";
import PersonalInformationView from "./PersonalInformationView";
import PreferencesView from "./PreferencesView";
import UserAccessView from "./UserAccessView";

function Settings(): JSX.Element {
    const { tab, id } = useUrlSearch() as { tab?: string; id?: string };
    const navigate = useNavigate();

    if (!tab) {
        navigate(addQueryParamsToUrl(URL.settings.index, { tab: MenuItemKeys.PROFILE }));
    }

    switch (tab) {
        case MenuItemKeys.PROFILE:
            return <PersonalInformationView />;
        case MenuItemKeys.PREFERENCES:
            return <PreferencesView />;
        case MenuItemKeys.ACCOUNTS:
            return <CompanyAccounts />;
        case MenuItemKeys.ORGANISATION:
            return <CompanyInformationView />;
        case MenuItemKeys.USERS:
            return <UserAccessView />;
        case MenuItemKeys.BILLING:
            return <BillingSubscriptionView />;
        case MenuItemKeys.PARTIES:
            if (id) {
                return <PartnerView />;
            }
            return <PartnersView />;
        case MenuItemKeys.GIT:
            return <GitConnectionsView />;
        case MenuItemKeys.API_KEYS:
            return <ApiKeysView />;
        default:
            return <PersonalInformationView />;
    }
}

export default function SettingsPage(): JSX.Element {
    const lang = useLang<AccountLang>();
    const { userProfile, claims, accountUser } = useAuthContext();
    const isAdmin = useHasAccountRights(AccountRights.ADMIN);

    const { whiteLabelDomain } = useWhiteLabelContext();
    const isWhitelabelOwner = isDomainOwner(claims, whiteLabelDomain?.id);
    const isBillingMenuVisible = whiteLabelDomain ? isWhitelabelOwner : isAdmin;

    if (!claims || !userProfile || !accountUser) {
        return <ContentLoader />;
    }
    return (
        <AppLayout getMenuItems={() => getMenuItems(lang, isBillingMenuVisible, isWhitelabelOwner)}>
            <Settings />
        </AppLayout>
    );
}
