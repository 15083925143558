import { flexRender, Row, Table } from "@tanstack/react-table";
import { Virtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import { Dispatch, SetStateAction, useState } from "react";
import { ExpandRowButton } from "./ExpandRowButton";
import { SelectRowCheckbox } from "./SelectRowCheckbox";
import { SelectionMode } from "./Table";
import { isExpandableRow, TableRow } from "./TableTools";

function doRowClick(rowId: string, setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>): void {
    setRowSelection?.(prevRowSelection => {
        if (prevRowSelection[rowId]) {
            return {};
        }

        const newSelection: Record<string, boolean> = {} as Record<string, boolean>;
        newSelection[rowId] = true;
        return newSelection;
    });
}

function SubComponents<T>({ subRows, table }: { subRows: Row<TableRow<T>>[]; table: Table<TableRow<T>> }): JSX.Element {
    const meta: { setRowSelection?: Dispatch<SetStateAction<Record<keyof T, boolean>>>; rowSelection?: Record<keyof T | string, boolean> } | undefined =
        table.options.meta;
    return (
        <>
            {subRows.map(row => (
                <div key={row.id} className="flex items-center gap-4">
                    {!!row.original.key && meta?.setRowSelection && <SelectRowCheckbox row={row} table={table} />}
                    {row.original.render?.(!!row.original.key && !!meta?.rowSelection?.[row.original.key])}
                </div>
            ))}
        </>
    );
}

function TableCells<T>({ table, row }: { table: Table<TableRow<T>>; row: Row<TableRow<T>> }): JSX.Element {
    const meta:
        | {
              isTree?: boolean;
              selectionMode?: SelectionMode;
              rowSelection?: Record<string, boolean>;
              setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>;
          }
        | undefined = table.options.meta;
    const [isHovered, setIsHovered] = useState(false);

    const isSelected = row.getIsSelected() || (row.subRows.length && row.getIsAllSubRowsSelected());

    return (
        <div
            className="flex"
            {...(meta?.selectionMode === SelectionMode.ROW
                ? { onClick: () => doRowClick(row.id, meta.setRowSelection), onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false) }
                : {})}
        >
            {row.getVisibleCells().map(cell => {
                const firstColumnIndex = meta?.isTree && meta?.selectionMode === "checkbox" ? 1 : 0;
                const isFirstColumn = cell.column.getIndex() === firstColumnIndex;
                return (
                    <div
                        key={cell.id}
                        children={
                            <div className="flex h-full items-center overflow-x-hidden">
                                {meta?.isTree && isFirstColumn && <ExpandRowButton row={row} table={table} />}
                                <div className="truncate">{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                            </div>
                        }
                        className={classNames("border border-b-grey-extra-light px-3 py-1", {
                            "border-x-blue-extra-light bg-blue-extra-light": isSelected,
                            "border-x-white bg-white": !isSelected && !isHovered,
                            "border-x-grey-extra-light bg-grey-extra-light": meta?.selectionMode === SelectionMode.ROW && isHovered && !isSelected
                        })}
                        style={{
                            width: cell.column.getSize(),
                            paddingLeft: meta?.isTree && isFirstColumn ? `${row.depth * 2}rem` : undefined
                        }}
                    />
                );
            })}
        </div>
    );
}

export function Body<T extends object>({ table, rows }: { table: Table<TableRow<T>>; rows: Row<T>[] }): JSX.Element {
    const meta: { isTree?: boolean; setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>> } | undefined = table.options.meta;

    return (
        <div>
            {rows.map(row => (
                <div id={row.id} key={row.id} className="w-fit">
                    <TableCells row={row} table={table} />
                    {isExpandableRow(row.original, meta?.isTree) && row.getIsExpanded() && (
                        <div className="px-3 py-1" children={<SubComponents table={table} subRows={row.subRows} />} />
                    )}
                </div>
            ))}
        </div>
    );
}

export function VirtualizedBody<T extends object>({
    table,
    rows,
    rowVirtualizer
}: {
    table: Table<T>;
    rows: Row<T>[];
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
}): JSX.Element {
    const meta: { isTree?: boolean; setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>> } | undefined = table.options.meta;
    const totalSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    const paddingTop = virtualRows?.[0]?.start || 0;
    const paddingBottom = virtualRows.length ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0) : 0;

    return (
        <div>
            {!!paddingTop && (
                <div className="flex w-fit">
                    <div style={{ height: paddingTop }} />
                </div>
            )}
            {virtualRows.map(virtualRow => {
                const row = rows[virtualRow.index];
                return (
                    <div className="w-fit" key={virtualRow.key} data-index={virtualRow.index} ref={rowVirtualizer.measureElement}>
                        <TableCells row={row} table={table} />
                        {isExpandableRow(row.original, meta?.isTree) && row.getIsExpanded() && (
                            <div className="p-2" children={<SubComponents table={table} subRows={row.subRows} />} />
                        )}
                    </div>
                );
            })}
            {!!paddingBottom && (
                <div className="flex w-fit">
                    <div style={{ height: paddingBottom }} />
                </div>
            )}
        </div>
    );
}
