import { Account, AccountInvitation, AccountUser } from "@vaultinum/vaultinum-api";
import {
    AppLayout,
    Breadcrumb,
    List,
    RowCard,
    SectionTitle,
    Spin,
    Summary,
    Tag,
    formatAddress,
    formatDate,
    formatName,
    getAccount,
    getAccountUsers,
    isDomainOwner,
    useAuthContext,
    useLang,
    useUrlSearch,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { ReactNode, useEffect, useState } from "react";
import { ViewWrapper } from "../../../components";
import { getMenuItems } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import { URL, formatRightsToAccountRole, isAccountInvitation } from "../../../services";

function GeneralInformation({ organisation, lang }: { organisation: Account; lang: AccountLang }): JSX.Element {
    return (
        <div className="flex w-full flex-col gap-1">
            <div className="flex">
                <div className="truncate text-xl font-bold text-primary" children={organisation.companyName} />
                <div className="ml-auto text-xs font-light" children={`${lang.date.createdOn(true)} ${formatDate(organisation.creationDate)}`} />
            </div>
            {organisation?.companyAddress && <span>{formatAddress(organisation.companyAddress)}</span>}
            <div className="w-auto">
                <Summary
                    rows={[
                        {
                            label: lang.accountInformation.form.companyIndustry,
                            children: organisation.companyIndustry
                        },
                        {
                            label: lang.accountInformation.form.companySize,
                            children: organisation.companySize
                        },
                        {
                            label: lang.accountInformation.form.companyRegistrationNumber,
                            children: organisation.companyRegistrationNumber
                        }
                    ]}
                />
            </div>
        </div>
    );
}

export default function DomainOrganisationDetailPage(): JSX.Element {
    const { id } = useUrlSearch() as { id: string };
    const lang = useLang<AccountLang>();
    const { claims } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [organisation, setOrganisation] = useState<Account | null>();
    const [accountUsers, setAccountUsers] = useState<AccountUser[]>([]);

    useEffect(() => getAccount(id, setOrganisation), [id]);
    useEffect(() => getAccountUsers(organisation, setAccountUsers), [organisation]);

    function getAccountUserTitle(accountUser: AccountUser | AccountInvitation): ReactNode {
        if (isAccountInvitation(accountUser)) {
            return <div className="italic text-warning">{lang.accountSettings.userAccessView.invitePending}</div>;
        }
        return formatName(accountUser);
    }

    function userRender(accountUser: AccountUser | AccountInvitation): JSX.Element {
        return (
            <RowCard color="slate">
                <div className="flex-1">
                    {getAccountUserTitle(accountUser)}
                    <div className="text-grey-primary">{accountUser.email}</div>
                </div>
                <Tag message={formatRightsToAccountRole(lang, accountUser.rights)} />
            </RowCard>
        );
    }
    return (
        <AppLayout getMenuItems={() => getMenuItems(lang, true, isDomainOwner(claims, whiteLabelDomain?.id))}>
            <ViewWrapper title={lang.organisations.title} dataId="domain-organisation">
                {!organisation && <Spin />}
                {organisation && (
                    <>
                        <Breadcrumb
                            items={[
                                {
                                    label: lang.organisations.title,
                                    href: URL.domainOrganisations.index
                                },
                                {
                                    label: organisation.companyName
                                }
                            ]}
                        />
                        <RowCard>
                            <GeneralInformation organisation={organisation} lang={lang} />
                        </RowCard>
                        <SectionTitle title={lang.organisations.users} />
                        <List list={accountUsers} render={userRender} />
                    </>
                )}
            </ViewWrapper>
        </AppLayout>
    );
}
