import { Table, TableProps } from "../Table";

function findLeafByPath<T extends { children?: T[] }>(data: T[], key: keyof T, value: string): string | null {
    for (const [index, d] of data.entries()) {
        if (d[key] === value) {
            return index.toString();
        }
        if (d.children) {
            const found = findLeafByPath(d.children, key, value);
            if (found) {
                return `${index}.${found}`;
            }
        }
    }
    return null;
}

export type TreeTableProps<T extends { children?: T[] }> = TableProps<T> & {
    selectedNode?: { key: keyof T; value: string };
    expandedNode?: { key: keyof T; value: string };
};

export namespace Tables {
    export function Tree<T extends { children?: T[] }>({
        data,
        columns,
        searchText,
        isVirtualized = true,
        storageKey,
        expandedNode,
        itemKey,
        selectedRows,
        setSelectedRows,
        onFilter,
        onLoadChildren,
        selectionMode,
        clearSelection,
        setClearSelection
    }: TreeTableProps<T>): JSX.Element {
        let expandedNodes: Record<string, boolean> | undefined;

        if (expandedNode) {
            const nodeIndex = findLeafByPath<T>(data, expandedNode.key, expandedNode.value);
            expandedNodes = nodeIndex?.split(".").reduce(
                (acc, current, index) => {
                    const key = index === 0 ? current : `${acc.prev}.${current}`;
                    acc.result[key] = true;
                    acc.prev = key;
                    return acc;
                },
                { result: {} as { [key: string]: boolean }, prev: "" }
            ).result;
        }

        return (
            <Table<T>
                columns={columns}
                data={data}
                searchText={searchText}
                isVirtualized={isVirtualized}
                storageKey={storageKey}
                onFilter={onFilter}
                onLoadChildren={onLoadChildren}
                expandedRows={expandedNodes}
                isTree
                itemKey={itemKey}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectionMode={selectionMode}
                clearSelection={clearSelection}
                setClearSelection={setClearSelection}
            />
        );
    }
}
