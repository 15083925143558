import { FullAudit } from "@vaultinum/vaultinum-api";
import filesize from "filesize";
import { CommonLang } from "../CommonLang";

const locale = "it-IT";
const formatSize = (size = 0): string => filesize(size, { base: 10, locale });

/* eslint max-len: 0 */
export const itCommon: CommonLang = {
    code: "it",
    getLang: "Italiano",
    apps: { name: "Applicazioni" },
    shared: {
        // shared between authentication, account and apps
        email: "Email",
        copy: "Copia", // to review
        invalidEmail: "Email non valida",
        name: "Nome",
        description: "Descrizione",
        confirm: "Confermare",
        add: "Aggiungere",
        cancel: "Annullare",
        save: "Salvare",
        edit: "Editare",
        change: "Cambiare",
        remove: "Rimuovere",
        delete: "Cancellare",
        free: "Gratuito",
        next: "Prossimo",
        previous: "Precedente",
        yes: "Sì",
        no: "No",
        skip: "Saltare",
        submit: "Inviare",
        done: "Finito",
        back: "Indietro",
        unknown: "Sconosciuto",
        loading: "Caricamento...",
        anyQuestion: "Ci sono delle domande?",
        contactUs: "Contattaci",
        requiredErrorMessage: "Questo campo è richiesto",
        minErrorMessage: minLength => `La lunghezza minima è ${minLength} caratteri`,
        atLeastOneUpperLowercase: "Almeno una lettera maiuscola e una minuscola", // to review
        atLeastOneSpecialCharacter: "Almeno un carattere speciale", // to review
        atLeastOneDigit: "Almeno una cifra", // to review
        noConsecutivesCharacters: "La password non deve includere 3 caratteri consecutivi del vostro indirizzo e-mail", // to review
        loginFailed: "Fallito l'accesso.",
        logoutFailed: "Fallito il logout.", // to review
        saveSuccessMessage: "Salvato con successo",
        saveErrorMessage: "Non è stato salvato",
        inviteSuccessMessage: "Invito inviato con successo",
        inviteErrorMessage: "Impossibile inviare l'invito",
        sendEmailSuccessMessage: "Email inviata con successo",
        sendEmailErrorMessage: "Impossibile inviare l'e-mail",
        signIn: "Accedi",
        invalidBirthdate: "Si prega di specificare una data di nascita corretta",
        dateFormat: "MM/GG/AAAA",
        successMessage: "successo",
        failMessage: "fallimento",
        password: "Password",
        confirmPassword: "Conferma la password",
        everything: "Tutto",
        updated: "Aggiornato",
        update: "Aggiornamento",
        apply: "Applicare",
        logOutProgress: "Disconnessione...",
        emailMustBeDifferentFromYours: "L'indirizzo email deve essere diverso dal tuo",
        details: "Dettagli", // to review
        uploadMethod: "Metodo di upload", // to review
        uploadDescription: fullAudit => (fullAudit ? "Carica manualmente il tuo file" : "Deposita uno o più file, file compressi o cartelle"), // to review
        uploadTitle: "Caricamento", // to review
        seeAllItems: appInfoLang => `Vedi ${appInfoLang?.allItemsName || "tutti"}`, // to review
        overview: "Panoramica",
        selectedRepositories: {
            [1]: "1 repository selezionato", // to review
            plural: count => `${count} repository selezionati` // to review
        },
        selectManually: "Seleziona manualmente", // to review
        upload: "Carica",
        processing: "Elaborazione",
        ready: "Pronto",
        noResult: "Nessun risultato",
        included: "Incluso", // to review
        search: "Cerca", // to review
        results: {
            [0]: "0 risultato", // to review
            [1]: "1 risultato", // to review
            plural: count => `${count} risultati` // to review
        },
        characters: {
            [0]: "0 carattere", // to review
            [1]: "1 carattere", // to review
            plural: count => `${count} caraterri` // to review
        },
        invalidPhoneNumber: "Numero di telefono non valido", // to review
        other: "Altro",
        filterBy: (label: string) => `Filtra per ${label.toLocaleLowerCase()}`, // to review
        sortBy: (label: string) => `Ordina per ${label.toLocaleLowerCase()}`, // to review
        ascending: "Crescente", // to review
        descending: "Decrescente", // to review
        organisation: "Organizzazione",
        domain: "Dominio", // to review
        status: "Stato",
        empty: "vuoto", // to review
        months: {
            january: "Gennaio", // to review
            february: "Febbraio", // to review
            march: "Marzo", // to review
            april: "Aprile", // to review
            may: "Maggio", // to review
            june: "Giugno", // to review
            july: "Luglio", // to review
            august: "Agosto", // to review
            september: "Settembre", // to review
            october: "Ottobre", // to review
            november: "Novembre", // to review
            december: "Dicembre" // to review
        },
        changeFilter: "Per ampliare la ricerca, cambiare o rimuovere il filtro precedente", // todo to review
        selectAccount: "Seleziona un account", // todo to review
        failedFetchingAccount: "Errore durante il recupero dell'account.", // to review
        others: "Altri" // to review
    },
    menu: {
        failedToGetMenuItems: "Si è verificato un errore durante il caricamento del menu" // to review
    },
    date: {
        on: "il",
        doneOn: isFeminine => (isFeminine ? "Fatta il" : "Fatto el"), // to review
        createdOn: isFeminine => (isFeminine ? "Creata il" : "Creato il"),
        updatedOn: isFeminine => (isFeminine ? "Aggiornata il" : "Aggiornato il"),
        completedOn: isFeminine => (isFeminine ? "Completata il" : "Completato il"),
        requestedOn: isFeminine => (isFeminine ? "Inviata il" : "Inviato il"),
        addedOn: isFeminine => (isFeminine ? "Aggiunta il" : "Aggiunto il")
    },
    settingsMenu: {
        signOut: "Uscire",
        signedInAs: "Registrato come:",
        companyAccount: "Organizzazione:",
        settings: "Impostazioni", // to review
        profileSettings: "Impostazioni del profilo",
        accountSettings: "Impostazione dell'organizzazione",
        switchTo: "Passare a un'altra organizzazione:",
        manageAccounts: "Gestire le organizzazoni",
        newOrganization: "Nuova organizzazione" // to review
    },
    price: {
        allPlans: "Tutti i Pianis", // to review
        priceOnDemand: "Prezzo su preventivo",
        getStarted: "Inizia",
        title: {
            accessClause: "Clausola di accesso", // to review
            bipartite: "Bipartito", // to review
            tripartite: "Tripartito", // to review
            deposit: "Deposito", // to review
            depositUpdate: "Aggiornamento del deposito", // to review
            depositUnlimitedPlan: "Piano di Deposito Illimitato", // to review
            elementCheckedDeposit: "Deposito Verificato", // to review
            contentCheckedDeposit: "Deposito Controllato", // to review
            oneShot: "One Shot" // to review
        },
        features: {
            fiveSurveys: "Cinque questionari", // to review
            multiUserAccess: "Accesso multiutente", // to review
            illustratedReport: "Rapporto illustrato", // to review
            recommendations: "Raccomandazioni", // to review
            customerSupport: "Assistenza clienti", // to review
            oneSurvey: "Un questionario", // to review
            unlimitedNumberOfSurvey: "Numero illimitato di questionari", // to review
            encryptedDeposit: "Deposito cifrato", // to review
            certificateOfDeposit: "Certificato di deposito", // to review
            freeUpdates: "Aggiornamenti gratuiti", // to review
            contractTemplate: "Modello di contratto", // to review
            legalTermsAtYourService: "L'ufficio legale al vostro servizio", // to review
            beneficiaryDashboardAccess: "Accesso al dashboard per i beneficiari", // to review
            vaultinumAsSignator: "Vaultinum come firmatario", // to review
            oneOnlineAssessment: "Un'autovalutazione online", // to review
            sourceCodeScan: "Scansione del codice sorgente", // to review
            expertAnalysis: "Analisi degli esperti", // to review
            multipleProducts: "Prodotti multipli", // to review
            multipleCompanies: "Aziende multiple" // to review
        }
    },
    payment: {
        checkout: {
            name: "Checkout"
        },
        proceed: "Procedere al pagamento", // to review
        downloadReceipt: "Scarica ricevuta", // to review
        downloadReceiptError: "Si è verificato un errore durante il download della ricevuta", // to review
        payment: {
            price: "Prezzo",
            promoCode: "Codice promozionale", // to review
            promoCodePlaceholder: "Ex: GPBM6PYXE", // to review
            promoCodeError: "Questo codice promozionale non è valido", // to review
            taxes: taxValue => `Tasse (${taxValue}% IVA)`, // to review
            discount: "Sconto", // to review
            total: "Totale", // to review
            name: "Pagamento",
            message: "Scelga il metodo di pagamento:", // to review
            creditCard: {
                name: "Carta di credito o di debito", // to review
                cardName: "Nome della carta", // to review
                saveAcceptance:
                    "Selezionando questa casella, si accetta di salvare questo metodo di pagamento in modo che venga offerto per gli ordini futuri. Tuttavia, questo metodo non sostituirà il vostro attuale metodo di pagamento predefinito." // todo review
            },
            sepaDebit: {
                name: "Addebito SEPA", // to review
                accountHolder: {
                    name: "Nome del titolare del conto",
                    namePlaceholder: "Nome completo" // to review
                },
                iban: "IBAN",
                mandateAcceptance:
                    "Fornendo i tuoi dati di pagamento e confermando questo pagamento, autorizzi (A) Vaultinum e Stripe, il nostro fornitore di servizi di pagamento, a inviare istruzioni alla tua banca per addebitare il tuo conto e (B) la tua banca ad addebitare il tuo conto in conformità con tali istruzioni. Come parte dei tuoi diritti, hai diritto a un rimborso dalla tua banca secondo i termini e le condizioni del tuo accordo con la tua banca. Un rimborso deve essere richiesto entro 8 settimane a partire dalla data in cui il tuo conto è stato addebitato. I suoi diritti sono spiegati in una dichiarazione che può ottenere dalla sua banca. Accetti di ricevere notifiche per gli addebiti futuri fino a 2 giorni prima che avvengano."
            },
            activationCode: {
                name: "Codice di attivazione",
                message: "Inserisci il tuo codice di attivazione:", // to review
                placeholder: "Codice di attivazione..."
            },
            method: "Metodo di pagamento" // to review
        },
        done: {
            name: "Fatto", // to review
            message: "Il tuo acquisto è stato completato con successo!" // to review
        },
        failed: {
            name: "Fallito",
            message: "Vi è stato un errore durante il pagamento, si prega di riprovare.", // to review
            backToPayment: "Ritorno al pagamento", // to review
            unableToConfirmCard: "Impossibile confermare la carta",
            callbackError:
                "Si è verificato un problema durante la finalizzazione del pagamento. L'addebito è stato effettuato. Se provi a pagare di nuovo, l'addebito potrebbe essere doppio. La preghiamo di contattarci in modo da poterle dare un coupon gratuito prima di riprovare." // to review
        },
        billing: {
            address1: "Indirizzo linea 1",
            address: "Indirizzo di fatturazione", // todo to review
            billingInformation: "Informazioni sulla fatturazione",
            city: "Città",
            country: "Paese",
            companyName: "Nome dell'azienda",
            vatNumber: "Numero di partita IVA",
            wrongVatFormat: "Il formato del numero di partita IVA non è corretto",
            taxIdRegistered: "Partita IVA già registrata", // to review
            vatNumberPlaceholder: "IT12345678912",
            notProvided: "Non fornita",
            zipCode: "CAP",
            billingDisclaimer: "I tuoi dati saranno utilizzati per l'acquisto. Si prega di assicurarsi che i dettagli non manchino e che siano accurati.", // todo to review
            formWarningMissingDetails: "Mancano i dettagli di fatturazione richiesti", // todo to review
            billingContact: "Contatto per la fatturazione", // to review
            billingContactPlaceholder: "Inserire l'email del contatto di fatturazione" // to review
        },
        frequency: {
            day: "giorno",
            week: "settimana",
            month: "mese",
            year: "anno"
        },
        summary: {
            order: "Riepilogo ordine", // to review
            billingAddress: "Indirizzo di fatturazione", // to review
            paymentMethod: "Metodo di pagamento", // to review
            disclaimer: "Riceverai un'e-mail di conferma con i dettagli dell'ordine" // to review
        },
        method: {
            expired: "Scaduta", // to review
            addPaymentMethod: "Aggiungi un metodo di pagamento", // to review
            expirationDate: "Scadenza", // to review
            errorListingPaymentMethods: "Si è verificato un errore durante il recupero dell'elenco dei metodi di pagamento",
            errorAddingPaymentMethod: "Si è verificato un errore durante l'aggiunta del metodo di pagamento" // to review
        }
    },
    industryList: {
        businessConsultancyManagement: "Affari, consulenza o gestione",
        accountancyBankingFinance: "Contabilità, banche o finanza",
        energyUtilities: "Energia e servizi pubblici",
        engineeringManufacturing: "Ingegneria o produzione",
        environmentAgriculture: "Ambiente o agricoltura",
        softwarePublisher: "Editore di software",
        iTServices: "Servizi IT",
        law: "Legge",
        lawEnforcementSecurity: "Applicazione della legge e sicurezza",
        leisureHospitalityTourism: "Tempo libero, ospitalità o turismo",
        mediaDigitalPublicRelations: "Media, digitale, PR",
        propertyConstruction: "Proprietà o costruzione",
        publicServicesAdministration: "Servizi pubblici o amministrazione",
        scientificResearch: "Ricerca scientifica",
        pharmaceuticalsHealthcare: "Prodotti farmaceutici, sanità",
        education: "Educazione",
        transportLogistics: "Trasporto o logistica",
        other: "Altro"
    },
    cookieConsent: {
        accept: "Accettare", // To review
        decline: "Declinare", // To review
        description:
            "Usiamo i cookie per analizzare il traffico e migliorare il traffico e migliorare l'esperienza dell'utente. Puoi leggere il nostro avviso legale per saperne di più su come trattiamo i dati." // To review
    },
    survey: {
        preamble: "Preambolo",
        resetQuestion: "Azzerare la risposta",
        extraInformation: "Informazioni supplementari...",
        pleaseDescribe: "(si prega di fornire una descrizione)",
        quickFix: "Correzione rapida", // to rewiew
        longFix: "Correzione lunga", // to rewiew
        evaluationImpact: {
            critical: "Impatto critico", // to rewiew
            high: "Impatto elevato", // to rewiew
            low: "Impatto basso" // to rewiew
        },
        priority: "Priorità", // to rewiew
        affects: "Influisce su", // to rewiew
        status: "Stato", // to review
        pending: "In attesa", // to review
        updated: "Aggiornato", // to review
        obsolete: "Obsoleto", // to review
        updateAnswer: "Aggiorna risposta", // to review
        updateAnswerError: "Si è verificato un errore durante l'aggiornamento della risposta", // to review
        updatedByReviewer: "Aggiornato da un esperto di Vaultinum", // to review
        yourInitialAnswer: "La tua risposta iniziale", // to review
        initialAnswer: "La risposta iniziale", // to review
        updateYourCurrentAnswer: "Aggiorna la tua risposta attuale", // to review
        isObsolete: "Questa raccomandazione è ormai obsoleta", // to review
        obsoleteReason: "Questa domanda non esiste più nella versione attuale dell'indagine", // to review
        beforeStart: "Prima di cominciare",
        domainExtension: "Estensione dominio", // to review
        countryList: "Elenco dei paesi...", // to review
        trademarkClass: "Classe marchio...", // to review
        filterQuestionWith: "Domande con...", // to review
        questionWithNotes: "Note", // to review
        questionWithRecommendations: "Raccomandazioni", // to review
        sections: "Sezioni", // to review
        recommendations: "Raccomandazioni" // to review
    },
    report: {
        completed: "Completato",
        industryAverage: "media del settore", // to review
        score: "punteggio" // to review
    },
    fileTree: {
        lines: "Linee", // to review
        size: "Dimensione" // to review
    },
    codeInput: {
        codeValidationMessage: "Verifica del codice...", // to review
        codeValidationMessageError: "Codice non valido, per favore riprova.", // to review
        codeValidationMessageSuccess: "Codice convalidato." // to review
    },
    register: {
        acceptTermsConditionsAndPrivacyPolicyErrorMessage: "I termini e le condizioni e l'informativa sulla privacy devono essere accettati.",
        alreadyRegistered: "Già registrato?",
        confirmPasswordErrorMessage: "Le password non corrispondono",
        createAccount: "Creare un account",
        iAcceptThe: "Accetto i",
        join: company => `Unisciti a ${company}`,
        login: "Login",
        logout: "Disconnettiti",
        privacyPolicy: "l'informativa sulla privacy",
        register: "Registra",
        registerWelcome: "Compila il modulo sottostante per creare un nuovo account.",
        termsConditions: "Condizioni generali di utilizzo della piattaforma",
        failMessage: "Impossibile registrarsi" // to review
    },
    onboarding: {
        title: "Benvenuto a Vaultinum!",
        subtitle: "Terza parte indipendente di fiducia specializzata nella protezione e nell'audit dei beni digitali."
    },
    client: {
        supplier: "Fornitore",
        beneficiary: "Beneficiario"
    },
    fullAudit: {
        errors: {
            notFound: "Due Diligence non trovata."
        },
        status: {
            [FullAudit.Status.OPEN]: "Aperto", // to review
            [FullAudit.Status.IN_PROGRESS]: "In corso", // to review
            [FullAudit.Status.BLOCKED]: "Bloccato", // to review
            [FullAudit.Status.CANCELLED]: "Annullato", // to review
            [FullAudit.Status.DONE]: "Terminato" // to review
        }
    },
    parties: {
        newParty: "Nuova parte",
        sendInvitation: "Invia invito",
        sendInvitationByEmail: "Invia invito via e-mail",
        inputSupplierEmail: "Per favore, specifica l'email del tuo contatto:",
        invitation: companyName =>
            `Invita il tuo contatto a collegarsi a “${companyName}” e inizia a collaborare sugli escrow agreements e/o audit. La nuova parte sarà in grado di visualizzare le informazioni condivise su audit / contratto di escrow e la ragione sociale, l'indirizzo e la partita IVA della tua azienda. Se il tuo contatto non ha ancora un account Vaultinum, sarà invitato a crearne uno. Grazie di cliccare nella casella qui sotto se si desidera ricevere una notifica via e-mail.`,
        alreadyPartner: "Questo indirizzo email è già un partner della tua organizzazione", // to review
        alreadyReceivedInvitation: "Questo indirizzo email ha già ricevuto un invito", // to review
        alreadySentInvitation: "Un invito è già stato inviato a questo indirizzo email", // to review
        sendInvitationConfirmation: (email: string) => `Sei sicuro di voler inviare nuovamente l'invito al seguente indirizzo email: ${email}`
    },
    accountInformation: {
        title: "Informazioni sull'organizzazione",
        form: {
            companyName: "Nome dell'organizzazione",
            companyRegistrationNumber: "Numero di registrazione",
            companyNationality: "Nazionalità",
            companyIndustry: "Industria",
            companySize: "Taglia dell'organizzazione",
            address: {
                title: "Indirizzo dell'organizzazione", // to review
                line1: "Indirizzo", // to review
                line1PlaceHolder: "Nome della strada, numero", // to review
                line2: "Linea di indirizzo 2", // to review
                line2PlaceHolder: "Edificio, pavimento", // to review
                city: "Città", // to review
                postalCode: "Codice postale", // to review
                postalCodePlaceHolder: "00000", // to review
                country: "Paese" // to review
            },
            billing: {
                invoiceCompanyName: "Nome dell'azienda che ha emesso la fattura", // to review
                billingInformationInvoiceCompanyName:
                    "Per impostazione predefinita, il nome dell'azienda viene visualizzato sulla fattura. Se invece si desidera visualizzare un nome personalizzato, inserirlo qui:", // to review
                billingInformationInvoicePostalAddress: "Se si desidera aggiungere un indirizzo postale diverso a ogni fattura, inserirlo qui", // to review
                billingInformationQuestions: "Avete domande sulla vostra fatturazione?" // to review
            }
        },
        roleAdminIsRequired: "Devi avere un ruolo di amministratore o di rappresentante per modificare le informazioni dell'azienda", // to review
        notes: {
            // to review all section
            title: "Note",
            deleteNote: "Cancella nota",
            showNotes: "Mostra note",
            permanentlyDeleteNote: "Questo cancellerà definitivamente la nota",
            visibleBy: "Visibile da",
            updatedBy: "Ultimo aggiornamento da",
            reviewers: "recensori",
            membersOf: accountName => `membri di ${accountName}`
        }
    },
    seo: {
        registerTitle: "Registratevi sulla piattaforma online | Vaultinum", // to review
        registerDescription:
            "Registratevi sulla piattaforma di gestione ed eseguite un deposito di IP, gestite i contratti di escrow o eseguite una due diligence tecnologica | ISO27001", // to review
        loginTitle: "Accedere alla piattaforma di gestione online | Vaultinum", // to review
        loginDescription:
            "Accedete alla piattaforma di gestione online ed eseguite un deposito di IP, gestite i contratti di escrow o eseguite una due diligence tecnologica | ISO27001", // to review
        homeTitle: "Quale azione vuoi eseguire ? | Vaultinum", // to review
        homeDescription:
            "La piattaforma di gestione consente di effettuare un deposito di PI, di gestire gli contratto di escrow o di eseguire una due diligence tecnologica | ISO27001" // to review
    },
    git: {
        gitConnection: "Connessione git", // to review
        description: fullAudit => {
            if (fullAudit) {
                return "Caricare il codice direttamente da un provider Git"; // to review
            } else {
                return "Sincronizzate i vostri repository di codice a intervalli regolari o in base agli eventi di git"; // to review
            }
        },
        notConfigured: "Sembra che non sia stata ancora impostata alcuna connessione a Git", // to review
        notConfiguredDetails:
            "Per configurare il sistema di controllo di versione distribuito online (ad esempio GitHub), fare clic sul pulsante sottostante e seguire le istruzioni. Tornare qui una volta terminato.", // to review
        selectSource: "Seleziona sorgente",
        selectRepository: "Seleziona le directory dei codici", // to review
        selectBranch: "Seleziona la filiale", // to review
        selectFrequency: "Seleziona una frequenza", // to review
        defaultBranch: "ramo predefinito", // to review
        frequency: {
            weekly: "settimanale", // to review
            monthly: "Mensile", // to review
            every3Month: "Ogni 3 mesi", // to review
            every6month: "Ogni 6 mesi", // to review
            oneShot: "One shot" // to review
        },
        connectionActive: "Connessione git attiva", // to review
        errors: {
            // To review all section
            connectionSuspended: "Connessione git sospesa",
            connectionUninstalled: "Connessione git disinstallata",
            connectionNotAccessible: "Connessione git non accessibile",
            repositoryNotAccessible: "Deposito git non accessibile",
            repositoryNotFound: "Deposito git non trovato",
            branchDeleted: "Ramo eliminato",
            archiveSizeExceeded: "La dimensione dell'archivio supera il limite",
            operationFailed: "Operazione git fallita"
        },
        addRepository: "Aggiungi repository", // to review
        addAllRepositories: "Aggiungi tutte le directory di codice", // to review
        branch: "filiale", // to review
        selectedRepositories: "Repository selezionati", // to review
        warningCheckoutConfirmation: "Una volta confermato, non potrai più modificare il titolo, la descrizione e i repository selezionati", // to review
        frequencyTitle: "Frequenza", // to review
        summaryError: "Si è verificato un errore durante il recupero delle informazioni", // to review
        codeRepository: "Directory dei codici", // to review
        source: "Fonte", // to review
        repositoriesError: "Si è verificato un errore durante il recupero delle directory di codice", // to review
        setup: "Impostare", // to review
        codeRepositoryEmpty: "Aggiungi alcuni repository di codice e seleziona un ramo" // to review
    },
    table: {
        apply: "Applica", // to review
        search: "Cerca", // to review
        filter: "Filtra", // to review
        sort: "Ordina", // to review
        selectAll: "Seleziona tutto", // to review
        clearAll: "Cancella tutto", // to review
        sortAsc: "Ordina per ordine crescente", // to review
        sortDesc: "Ordina per ordine decrescente" // to review
    },
    email: {
        subject: {
            billingSupportRequest: "Richiesta di supporto - fatturazione", // to review
            supportRequest: "Richiesta di supporto" // to review
        }
    },
    uploadFile: {
        description: (fileType: string) => `Seleziona o trascina e rilascia il tuo file ${fileType}`, // to review
        file: "File", // to review
        size: "Dimensione", // to review
        noFileUploaded: "Nessun file caricato", // to review
        preview: "Anteprima", // to review
        formatSize,
        tooManyFiles: "È accettato un solo file", // to review
        invalidFileType: (fileName: string, fileType: string) => `Il file ${fileName} non è un file ${fileType} valido`, // to review
        acceptedFileTypes: (fileTypes: string) => `I tipi di file accettati sono: ${fileTypes}`, // to review
        actions: "Azioni", // to review
        minResolution: (resolution: number) => `Dimensione minima consigliata: ${resolution}px (larghezza/altezza)`, // to review
        errorFetchingFiles:
            "Si è verificato un errore durante il recupero dei file dal nostro server. Per favore contattaci per risolvere questo problema prima di provare a caricare il files.",
        errorWhileUploading: "Errore durante il caricamento del file. Contatta il nostro supporto clienti.",
        files: "File",
        filesAlreadyExistError: files => `${files} esistono già`,
        maxFileSizeError: (filename, maxFileSize) => `${filename} è più grande di ${maxFileSize}`,
        maxNumberOfFilesError: maxNumberOfFiles => `Non puoi caricare più di ${maxNumberOfFiles} file`,
        maxTotalFileSizeError: maxTotalFileSize => `Non puoi caricare più di ${maxTotalFileSize} file`,
        minNumberOfFilesError: "È necessario caricare almeno un file",
        noFilesUploadedYet: "Nessun file caricato ancora",
        notPossibleToDelete: filename => `Non è stato possibile cancellare ${filename}`,
        progress: "Progresso",
        uploadInstructions: "Trascina e rilascia i tuoi file qui, o clicca per selezionare i file",
        uploadLimitations: (acceptedFileTypes, maxFileSize, maxTotalSize) =>
            `${
                !acceptedFileTypes?.length ? "Tutti i tipi di file sono accettati" : `Sono accettati solo alcuni tipi di file: ${acceptedFileTypes}`
            }. Nessun file può essere più grande di ${maxFileSize}. La dimensione totale non può superare ${maxTotalSize}.`,
        waitUntilFinished: "Devi aspettare che il caricamento sia finito"
    },
    chart: {
        monthAbbreviation: "m",
        yearAbbreviation: "a"
    }
};
