import { motion } from "framer-motion";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { Modal, Separator, Spin, openNotificationWithIcon } from "../../../design-system";
import { CommonLang, useLang } from "../../../lang";
import { BaseTrackingEvents, trackingService } from "../../analytics";
import { AppLayoutActions, LayoutContextProvider, useAuthContext } from "../../contexts";
import { getAppNameForTrackingService, getMatchingItem } from "../../helpers";
import { useAvailableAppItems, useToggle } from "../../hooks";
import { SHARED_URL, logout } from "../../services";
import { AppMenuItem } from "./AppsDropdownMenu";
import { DropdownItem } from "./DropdownMenu";
import LeftNavbar, { MenuItem } from "./LeftNavBar";
import SubMenuItem, { SubItemProps, SubMenuItemProps } from "./SubMenuItem";
import TopNavBar from "./TopNavBar";

function isSubItemProps(item: SubItemProps | string): item is SubItemProps {
    return typeof item !== "string";
}

function getActiveMenuItem(location: Pick<Location, "pathname" | "search">, menuItems?: MenuItem[][]): MenuItem | undefined {
    return menuItems ? getMatchingItem(location, menuItems.flat()) : undefined;
}

function getActiveSubMenuItem(location: Pick<Location, "pathname" | "search">, subMenuItems: SubMenuItemProps[]): SubItemProps | undefined {
    return getMatchingItem(
        location,
        subMenuItems.flatMap(subMenuItem => subMenuItem.items.filter(isSubItemProps))
    );
}

export default function NavigationMenu({
    children,
    menuItems,
    dropdownItems = [],
    layout,
    hideExpandButton,
    isMenuDefaultExpanded = true,
    layoutActions
}: {
    children: ReactNode;
    menuItems?: MenuItem[][];
    dropdownItems?: DropdownItem[];
    layout?: "logout-only" | "minimal" | "full";
    hideExpandButton?: boolean;
    isMenuDefaultExpanded?: boolean;
    layoutActions?: AppLayoutActions | null;
}): JSX.Element {
    const [subMenuItems, setSubMenuItems] = useState<SubMenuItemProps[] | undefined>();
    const [activeMenuItemKey, setActiveMenuItemKey] = useState<string | undefined>();
    const [activeSubMenuItemKey, setActiveSubMenuItemKey] = useState<string | undefined>();
    const [loggingOut, setLoggingOut] = useState(false);
    const [isExpanded, toggleExpanded, setIsExpanded] = useToggle();
    const { userProfile } = useAuthContext();
    const lang = useLang<CommonLang>();
    const appsMenuItems = useAvailableAppItems();
    const navigate = useNavigate();
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | undefined>();

    useEffect(() => {
        function handleResize() {
            setContentHeight(contentRef.current?.clientHeight);
        }
        if (contentRef.current) {
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [contentRef.current]);

    const menuVariants = {
        open: { width: 225 },
        closed: { width: 0 }
    };

    async function onLogout(): Promise<void> {
        try {
            setLoggingOut(true);
            await logout();
        } catch (error) {
            openNotificationWithIcon({ type: "error", description: `${lang.shared.logoutFailed}` });
        }
        setLoggingOut(false);
        window.location.assign(SHARED_URL.app.login);
    }

    function onAppItemClick(item: AppMenuItem) {
        trackingService.sendEvent(BaseTrackingEvents.AppLogoClicked, {
            App: getAppNameForTrackingService(item.href),
            Source: "Apps Dropdown"
        });
    }

    const location = useLocation();

    useEffect(() => {
        const activeMenuItem = getActiveMenuItem(location, menuItems);
        setActiveMenuItemKey(activeMenuItem?.key);
        setSubMenuItems(activeMenuItem?.subMenuItems || []);
        setIsExpanded(!!activeMenuItem?.subMenuItems?.length);
        if (activeMenuItem?.subMenuItems?.length) {
            const activeSubMenuItem = getActiveSubMenuItem(location, activeMenuItem.subMenuItems);
            setActiveSubMenuItemKey(activeSubMenuItem?.key);
        }
    }, [location, menuItems]);

    function onMenuItemClick(menuItem: MenuItem): void {
        menuItem.onClick?.();
        const nextUrl = menuItem.href;
        if (nextUrl) {
            const nextActiveMenuItem = getActiveMenuItem({ pathname: nextUrl, search: "" }, menuItems);
            if (nextActiveMenuItem?.key === activeMenuItemKey && nextActiveMenuItem?.subMenuItems?.length) {
                toggleExpanded();
            } else {
                navigate(nextUrl);
            }
        }
    }

    const isOpen = isExpanded && subMenuItems?.length;

    return (
        <div className="flex h-screen">
            <LeftNavbar
                menuItems={menuItems}
                dropdownItems={dropdownItems}
                homeHref="/app"
                onMenuItemClick={onMenuItemClick}
                activeMenuItemKey={activeMenuItemKey}
                hideExpandButton={hideExpandButton}
                isMenuDefaultExpanded={isMenuDefaultExpanded}
            />
            <div className="flex flex-1 flex-col overflow-x-auto">
                <TopNavBar onLogout={onLogout} userProfile={userProfile} appsMenuItems={appsMenuItems} onAppItemClick={onAppItemClick} layout={layout} />
                <div className="flex h-full overflow-hidden">
                    {subMenuItems && (
                        <motion.div
                            className="h-full overflow-y-auto overflow-x-hidden bg-white shadow-inner"
                            variants={menuVariants}
                            initial={isOpen ? "open" : "closed"}
                            animate={isOpen ? "open" : "closed"}
                        >
                            {subMenuItems.map(subMenuItem => {
                                return (
                                    <div key={subMenuItem.text}>
                                        <SubMenuItem subMenuItem={subMenuItem} activeSubMenuItemKey={activeSubMenuItemKey} />
                                        <Separator />
                                    </div>
                                );
                            })}
                        </motion.div>
                    )}
                    <div ref={contentRef} className="flex-1 overflow-y-auto bg-grey-extra-light p-8 shadow-inner">
                        <LayoutContextProvider value={{ contentHeight, layoutActions }} children={children} />
                    </div>
                </div>
            </div>
            {loggingOut && (
                <Modal isOpen hideFooter={true} isLoading lang={lang} size="sm">
                    <div className="flex justify-center space-x-4">
                        <Spin /> <span className="text-base font-semibold">{lang.shared.logOutProgress}</span>
                    </div>
                </Modal>
            )}
        </div>
    );
}
