import { it } from "@vaultinum/vaultinum-sdk";
import { AccountLang } from "../AccountLang";

/* eslint max-len: 0 */
const itLang: AccountLang = {
    ...it,
    account: {
        apps: {
            title: "Apps",
            plan: "Piano",
            payment: "Metodo di pagamento"
        }
    },
    invitation: {
        invitationNotFound: "Invito non trovato", // to review
        wrongMatchingEmail: "L'invito non corrisponde alla tua email", // to review
        invitationAlreadyAccepted: "Invito già accettato", // to review
        accountNoLongerExists: "L'organizzazione non esiste più", // to review
        invitationRejected: "Invito rifiutato", // to review
        invitationCanceled: "Invito annullato", // to review
        missingData: "Mancano alcuni dati", // to review
        unknownCode: "Codice sconosciuto" // to review
    },
    authentication: {
        login: "Login",
        rememberMe: "Ricordarsi di me",
        forgotPassword: "Password dimenticata?",
        resetPassword: "Ripristina la tua password",
        resetPasswordRetry: "Ripristina di nuovo la tua password ",
        recoverPasswordInstructions: "Inserisci il tuo indirizzo email e ti invieremo le istruzioni su come reimpostare la tua password.",
        resetPasswordInstructions: "Per favore scegli una nuova password.",
        resetPasswordSuccessMessage: "Ripristino della password con successo",
        verifyEmailSuccessMessage: "La tua email è stata verificata",
        notRegistered: "Non ancora registrato?",
        logout: "Disconnettiti",
        errors: {
            166: "Credenziali errate, email e password non corrispondenti.",
            loginFailed: "Accesso non riuscito."
        },
        loginFailedErrorMessage: "Accesso non riuscito.",
        tryAgain: "Riprova più tardi. Un'e-mail è già stata inviata.", // to review
        actionModeErrorMessage: "Modo non valido",
        oobCodeErrorMessage: "La tua richiesta è scaduta o il link è già stato utilizzato",
        resetPasswordErrorMessage: "Impossibile reimpostare la password",
        resetPasswordInfoMessage: "Se hai un account sulla piattaforma, vai alla tua casella di posta elettronica per reimpostare la password", // to review
        or: "O" // to review
    },
    homePage: {
        title: "Quale azione vuoi eseguire?",
        manageAccountSettings: "Gestire le impostazioni dell'organizzazione"
    },
    domain: {
        title: "Dominio",
        failedToGetDomain: "Impossibile recuperare il dominio" // to review
    },
    organisations: {
        title: "Organizzazioni",
        searchOrganisation: "Cerca per nome dell'organizzazione",
        noOrganisationFound: "Nessuna organizzazione trovata su questo dominio",
        viewOrganisation: "Vedi organizzazione",
        creationDate: "Data di creazione",
        registeredDate: "Data di registrazione",
        users: "Utenti"
    },
    sharedSettings: {
        // to review entire section
        noResultsSearch: "Non sono stati trovati membri per la tua ricerca",
        adminRole: "Admin",
        representativeRole: "Mandatario", // to review
        contributorRole: "Contributore",
        readOnlyRole: "Di sola lettura"
    },
    profileSettings: {
        warning: {
            emailNotVerified: "Il tuo indirizzo email non è stato ancora confermato ."
        },
        title: {
            personalAccount: "Impostazioni dell'account personale"
        },
        menu: {
            profile: "Profilo", // to review
            preferences: "Preferenze",
            companyAccounts: "Le mie organizzazioni" // to review
        },
        form: {
            birthdate: "Data di nascita",
            cellPhone: "Numero di telefono",
            email: "Indirizzo email",
            currentPassword: "Password attuale", // to review
            firstName: "Nome",
            lastName: "Cognome",
            nationality: "Nazionalità",
            personalInformation: "Informazione personale",
            preferences: "Preferenze",
            preferredLanguage: "Lingua preferita"
        },
        companyAccounts: {
            title: "Organizzazioni",
            whatIs: "Che cos'è un'organizzazione ?", // to review
            definition: "Un'organizzazione è l'azienda a cui si appartiene o che si rappresenta", // to review
            newAccount: "Nuova organizzazione",
            search: "Cerca per nome dell'organizzazione",
            leave: "Lascia",
            leaveAccount: companyName => `Lasciare organizzazione ${companyName}?`,
            leaveAccountDescription: companyName =>
                `Conferma di voler lasciare il "${companyName}" organizzazione?  Non sarete più in grado di per accedere a qualsiasi informazione relativa a questo organizzazione.`, // to review
            leaveAccountNonAdminDescription:
                "Un nuovo invito a questo organizzazione sarà necessario se si desidera unirsi di nuovo a questo organizzazione aziendale.", // to review
            leaveAccountAdminDescription:
                "Dato che sei l'unico utente \"Admin\" di questo organizzazione, puoi eliminare completamente l'organizzazione per tutti gli utenti o assegnare il ruolo Admin a un utente esistente.",
            newAdminToAssign: "Assegnare un nuovo amministratore",
            doNotAssignNewAdmin: "Non assegnare nuovi admin",
            deleteAccount: "Cancellare l'organizzazione",
            deleteAccountConfirmation: companyName => `Sei sicuro di voler eliminare l'organizzazione "${companyName}"?`,
            deleteAccountConfirmationDescription: "Tutti i dati e gli utenti legati a questo organizzazione saranno rimossi in modo permanente.",
            acceptInvitation: "Accettare",
            rejectInvitation: "Rifiutare",
            invitedBy: senderName => `Invitato da ${senderName}`,
            failedToJoinAccount: "Fallito l'accesso all'organizzazione",
            failedToRejectInvitation: "Fallito il rifiuto dell'invito" // to review
        }
    },
    accountSettings: {
        title: {
            organisationAccount: "Impostazioni dell'organizzazione"
        },
        rightContent: {
            switchTo: "Passare a un'altra organizzazione"
        },
        menu: {
            organisation: "Organizzazione", // to review
            generalInformation: "Informazioni generali",
            userAccess: "Accesso utente",
            parties: "Parti",
            billingAndSubscriptions: "Fatturazione e iscrizioni",
            git: "Git",
            apiKeys: "Chiavi API" // to review
        },
        userAccessView: {
            name: "Accesso utente",
            whatIs: "Cos'è un utente ?",
            definition:
                "Un utente è un collaboratore diretto della vostra azienda (ad esempio: un collega o un rappresentante legale). Quando si invia loro un invito, assicurarsi di impostare i diritti utente appropriati", // to review
            invitations: "Richieste di invito",
            changeRole: "Cambiare ruolo",
            removeFromAccount: "Rimuovi dall'organizzazione", // to review
            removeFromAccountConfirmationTitle: user => `Confermare la rimozione dell'utente ${user}`,
            removeFromAccountConfirmationContent: (user, account) => `Questo rimuoverà l'utente ${user} dall'organizzazione ${account}`,
            inviteUser: {
                name: "Invita l'utente",
                tooltip: "Il ruolo di amministratore è necessario per inviare un invito.", // to review
                emailPlaceholder: "Indirizzo email per invitare",
                rolePlaceholder: "Ruolo",
                errors: {
                    alreadyInAccountErrorMessage: "Questo utente fa già parte di questo organizzazione.", // to review
                    alreadyInvitedErrorMessage: "Questo utente ha già ricevuto un invito." // to review
                }
            },
            invitePending: "Invito in corso...",
            sendInvite: "Invia",
            resendInvite: "Invia di nuovo l'invito", // to review
            cancelInvitation: "Cancellare l'invito",
            search: "Cerca per email o nome",
            findMember: "Trova il membro",
            changeRoleTitle: user => `Cambiare ruolo per ${user}?`,
            selectRole: "Seleziona il ruolo",
            adminRoleDescription: "Ha pieno accesso amministrativo. Può invitare e rimuovere utenti e fare pagamenti",
            representativeRoleDescription:
                "Può invitare e può contribuire a fornire informazioni e dati a tutti i servizi accessibili da questo organizzazione.", // to review
            contributorRoleDescription: "Può contribuire a fornire informazioni e dati a tutti i servizi accessibili da questo organizzazione.",
            readOnlyRoleDescription: "Può visualizzare tutti i dati dei servizi in modalità di sola lettura",
            changeRoleSuccessMessage: "Ruolo aggiornato con successo",
            changeRoleErrorMessage: "Fallito l'aggiornamento del ruolo"
        },
        billingView: {
            plansAndSubscriptionsTitle: "Piani e abbonamenti", // to review
            billingInformationTitle: "Informazioni sulla fatturazione", // to review
            updateSubscription: "Aggiorna abbonamento", // to review
            updateSubscriptions: "Aggiorna abbonamento/i", // to review
            noPlanOrSubscription: "la tua organizzazione attualmente non ha piani o abbonamenti attivi.",
            invoice: {
                failed: "Si è verificato un errore durante il recupero delle fatture", // to review
                invoiceNumber: "Numero di fattura", // to review
                status: {
                    name: "Status", // to review
                    draft: "Brutta copia", // to review
                    void: "Vuoto", // to review
                    uncollectible: "Inesigibile", // to review
                    open: "Aprire", // to review
                    paid: "Pagato" // to review
                },
                date: "Data", // to review
                amount: "Quantità", // to review
                label: {
                    1: "Fattura", // to review
                    plural: () => "Fatture" // to review
                },
                product: "Prodotto", // to review
                failedToDownloadInvoice: "Si è verificato un errore durante il download della fattura" // to review
            },
            subscription: {
                failed: "Si è verificato un errore durante il recupero delle sottoscrizioni", // to review
                status: {
                    name: "Status", // to review
                    active: "Attivo", // to review
                    canceled: "Annullato", // to review
                    incomplete_expired: "Scaduto", // to review
                    past_due: "In ritardo" // to review
                },
                date: "Data", // to review
                amount: "Importo", // to review
                label: {
                    "1": "Sottoscrizione", // to review
                    plural: () => "sottoscrizioni" // to review
                },
                canceledAt: "Annullato a" // to review
            },
            paymentMethods: {
                title: "Metodi di pagamento",
                expirationDate: "Data di scadenza",
                deletePaymentMethod: "Elimina metodo di pagamento",
                deletePaymentMethodConfirmation: "Sei sicuro di voler eliminare questo metodo di pagamento?",
                deletePaymentMethodFailed: "Si è verificato un errore durante l'eliminazione del metodo di pagamento",
                listPaymentMethodFailed: "Si è verificato un errore durante il recupero dei metodi di pagamento"
            }
        },
        partiesView: {
            name: "Parti",
            whatIs: "Che cos'è una parte?",
            definition:
                "Una parte è un fornitore o un beneficiario con cui si lavora per gli audit/escrow. Una parte invitata è in grado di visualizzare le informazioni condivise su audit/accordi e il nome, l'indirizzo e il numero di registrazione della vostra azienda.",
            invitations: "Richieste di invito",
            sentYouAPartyRequest: companyName => `${companyName} desidera unirsi a voi come parte`,
            isNowYourParty: companyName => `${companyName} si è unito a voi come parte`,
            confirmReject: companyName => `Sei sicuro di voler rifiutare l'invito di ${companyName}?`,
            linkPartyAccount: "Collegare una parte",
            startWorkingTogether: partnerCompanyName =>
                `Grazie di confermare che accetti di essere aggiunto come nuovo parte della'“${partnerCompanyName}”.
                Con il statuto di parte, potrai visualizzare le informazioni condivise sugli audit e/o i contratti di escrow e vedere la ragione sociale, l'indirizzo e la partita IVA.`, // to review
            selectAnotherAccount: "Se desideri collegare un altra organizzazione, seleziona il conto corretto qui sotto",
            noPartyYet: "Non avete ancora invitato altre persone o ricevuto un invito.",
            invitationRejected: "Invito rifiutato",
            invitationCancelled: "Invito cancellato",
            errorOccured: "Si è verificato un errore durante il recupero delle vostre parti",
            invitationAlreadySent: "Invito già inviato",
            reportToAdmin: "L'utente non ha il diritto di invitare o accettare inviti da altre parti. Contattare l'amministratore dell'organizzazione"
        },
        gitView: {
            title: "Connessioni di controllo della versione", // to review
            newConnection: "Aggiungere una nuova connessione", // to review
            installingConnection: "Installazione del collegamento...", // to review
            deleteConnection: "Cancellare la connessione", // to review
            deleteConnectionConfirmation: "Si è sicuri di voler cancellare questa connessione git?", // to review
            selectConnection: "Selezionare la sorgente di connessione:", // to review
            featureComingSoon: "In arrivo!", // to review
            lastConnectionDate: "Collegato", // to review
            noResultsSearch: "Nessuna connessione git trovata", // to review
            installationConfirmationMessage: provider =>
                `Facendo clic su "Confermare", si verrà reindirizzati al sito ${provider} per completare l'installazione.`, // to review
            deletionConfirmationMessage: provider =>
                `Facendo clic su "Confermare", si verrà reindirizzati al sito web di ${provider} per eliminare la connessione.`, // to review
            installed: "Installato", // to review
            disconnected: "Disconnesso", // to review
            fetching: "Recupero delle directory del codice", // to review
            fetchFailed: "Impossibile recuperare le directory del codice", // to review
            lastConnected: date => `Ultimo collegamento ${date}` // to review
        },
        apiKeysView: {
            title: "Chiavi di accesso API", // to review
            newAccessToken: "Generare un nuovo token di accesso", // to review
            description: "I token generati possono essere utilizzati per accedere all'API Vaultinum.", // to review
            newApiKeyTitle: "Nuovo token di accesso API", // to review
            nameFieldPlaceholder: "Nome chiave API...", // to review
            keyEnvironments: {
                sandbox: "Chiave Sandbox", // to review
                production: "Chiave di produzione" // to review
            },
            generate: "Generare", // to review
            recapDescription: "Attenzione: Salva questa chiave, questa sarà l'unica volta che la vedrete.", // to review
            noResultFound: "Nessuna chiave API trovata.", // to review
            deleteApiKeyTitle: "Eliminare il token di accesso API", // to review
            deleteConfirmation: name => `Sei sicuro di voler eliminare la chiave API "${name}"?`, // to review
            maxReached: "Numero massimo di chiavi API raggiunto" // to review
        }
    },
    onboarding: {
        ...it.onboarding,
        verifyEmail: {
            sent: email => `Ti abbiamo inviato un'e-mail di attivazione all'indirizzo ${email}.`,
            confirm: "Si prega di verificare la tua email per continuare."
        },
        userProfile: {
            title: "Tuo profilo",
            successOnSave: "Informazioni del profilo salvate con successo.",
            errorOnSave: "Impossibile salvare le informazioni del tuo profilo."
        },
        invitations: {
            // to review all section
            title: "I vostri inviti",
            description: {
                "1": "Sei già stato invitato da un'organizzazione. Selezionala se vuoi aderire:",
                plural: count => `Sei già stato invitato da ${count} organizzazioni. Seleziona quelle a cui vuoi aderire :`
            },
            joinOrganisation: {
                "0": "Ignorare e creare la mia organizzazione",
                "1": "Unisciti a questa organizzazione",
                plural: count => `Unisciti a queste ${count} organizzazioni`
            },
            successOnJoin: {
                "1": "Ti sei unito a un'organizzazione con successo",
                plural: count => `Hai aderito con successo a ${count} organizzazioni.`
            },
            errorOnJoin: {
                "1": "Fallita l'adesione a un'organizzazione.",
                plural: count => `Non è riuscito a unirsi a ${count} organizzazioni.`
            }
        },
        account: {
            createAs: {
                title: "Creare come", // to review
                organisationMember: {
                    title: "Membro dell'organizzazione", // to review
                    description: "Creare e gestire un'organizzazione a nome della vostra azienda." // to review
                },
                representative: {
                    title: "Mandatario", // to review
                    description: "Creare e gestire un account per conto del cliente." // to review
                }
            },
            accountInfo: {
                title: isRepresentative => `Informazioni sull'organizzazione${isRepresentative ? " del suo cliente" : ""}` // to review
            },
            collaborate: {
                // to review entire section
                title: isRepresentative => (isRepresentative ? "Contatto cliente" : "Invitate i vostri colleghi"), // to review
                description: isRepresentative => {
                    if (isRepresentative) {
                        return "Grazie di fornire l'e-mail di contatto del tuo cliente. Si potrà condividere questo organizzazione come amministratore, garantendovi il pieno controllo.";
                    }
                    return "Invita i collaboratori diretti a lavorare con te. Potranno unirsi a questo organizzazione e avere il diritto di modificare e caricare i dati. Puoi sempre cambiare i loro ruoli in seguito nella pagina delle impostazioni dell'organizzazione, così come inviare altri inviti.";
                },
                collaboratorEmail: isRepresentative => `Email ${isRepresentative ? "di contatto del cliente" : "del collaboratore"}`, // to review
                addAnotherCollaborator: isRepresentative => `Aggiungere un altro ${isRepresentative ? "contatto cliente" : "collaboratore"}`, // to review
                sendInvitation: {
                    1: "Invia invito via e-mail",
                    plural: () => "Invia inviti via e-mail"
                }
            },
            successOnSave: isRepresentative => `${isRepresentative ? "L'organizzazione del tuo cliente " : "Il tuo account"} è stato creato con successo.`, // to review
            errorOnSave: isRepresentative => `Impossibile creare ${isRepresentative ? "l'organizzazione del tua cliente" : "la tua organizzazione"}.`
        },
        moreInfoLink: "Maggiori informazioni su perché e come utilizziamo le sue informazioni."
    }
};
export default itLang;
