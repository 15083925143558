import classNames from "classnames";
import { ElementType, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Size } from "../../referentials";
import { Buttons } from "../Button";
import { ChevronLeftIcon, ChevronRightIcon, CrossIcon } from "../Icons";
import { Separator } from "../Separator";

const MOBILE_BREAKPOINT = 768;

const DRAWER_SIZE: Record<Extract<Size, "md" | "lg" | "xl"> | "auto", number | "auto"> = {
    md: 500,
    lg: 800,
    xl: 1500,
    auto: "auto"
};

export type DrawerHeaderProps = {
    Icon: ElementType;
    title: string;
    subtitle?: string;
    navigation?: DrawerNavigationProps;
};

export type DrawerNavigationProps = {
    onForward?: () => Promise<void> | void;
    onPrevious?: () => Promise<void> | void;
};

export type DrawerProps = {
    header: DrawerHeaderProps;
    children: JSX.Element;
    isVisible: boolean;
    onClose: () => void;
    size?: keyof typeof DRAWER_SIZE;
    isResizable?: boolean;
};

function getMinDrawerSize(drawerSize: number): number {
    return Math.min(drawerSize, document.body.offsetWidth);
}

function Navigation({ onForward, onPrevious }: DrawerNavigationProps): JSX.Element {
    return (
        <div className="flex gap-2">
            <Buttons.Icon icon={ChevronLeftIcon} onClick={onPrevious} isLoading={false} size="sm" isDisabled={!onPrevious} />
            <Buttons.Icon icon={ChevronRightIcon} onClick={onForward} isLoading={false} size="sm" isDisabled={!onForward} />
        </div>
    );
}

function Header({
    Icon,
    title,
    subtitle,
    navigation,
    onClose
}: { navigation?: DrawerNavigationProps } & DrawerHeaderProps & { onClose: () => void }): JSX.Element {
    return (
        <div className="flex w-full gap-4 p-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-slate-primary">
                <Icon size="sm" color="white" />
            </div>
            <div className="flex flex-col justify-center">
                <div className="text-slate text-lg font-bold">{title}</div>
                {subtitle && <div className="text-slate text-xs">{subtitle}</div>}
            </div>
            <div className="flex flex-auto items-center justify-end gap-4">
                {!!navigation && <Navigation {...navigation} />}
                <Buttons.Icon onClick={onClose} fill="link" type="default" icon={CrossIcon} color="slate" isLoading={false} size="md" />
            </div>
        </div>
    );
}

export function Drawer({ header, children, isVisible, isResizable = true, onClose, size = "md" }: DrawerProps): JSX.Element | null {
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [width, setWidth] = useState<number>();

    const screenSize = document.body.offsetWidth;
    const drawerSize = size === "auto" && screenSize > MOBILE_BREAKPOINT ? screenSize / 2 : DRAWER_SIZE[size];
    const canResize = isResizable && screenSize > MOBILE_BREAKPOINT;

    function onMouseDown(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        e.preventDefault();
        setIsResizing(true);
    }

    function onMouseUp(): void {
        setIsResizing(false);
    }

    function onMouseMove(e: MouseEvent): void {
        if (!isResizing) {
            return;
        }
        const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        const maxWidth = document.body.offsetWidth;
        const minWidth = typeof drawerSize === "number" ? getMinDrawerSize(drawerSize) : screenSize;
        setWidth(offsetRight > minWidth ? Math.min(offsetRight, maxWidth) : minWidth);

        // dispatch resize event so that the children can adapt to the new size
        const resizeEvent = new Event("resize");
        window.dispatchEvent(resizeEvent);
    }

    useEffect(() => {
        if (!isVisible) {
            setIsRendered(false);
            return () => {};
        }
        setWidth(typeof drawerSize === "number" ? getMinDrawerSize(drawerSize) : screenSize);
        const timeout = setTimeout(() => {
            setIsRendered(true);
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [isVisible]);

    useEffect(() => {
        if (!canResize) {
            return () => {};
        }
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };
        // isResizing needed because it is a dependency of onMouseMove
    }, [canResize, isResizing]);

    return isVisible
        ? ReactDOM.createPortal(
              <div id="drawer">
                  <div
                      className={classNames("fixed top-0 left-0 z-50 h-full w-full bg-black transition-opacity ease-in-out", {
                          "opacity-50": isRendered,
                          "pointer-events-none opacity-0": !isRendered
                      })}
                      onClick={onClose}
                  />
                  <div
                      style={{ width }}
                      className={classNames("fixed top-0 right-0 z-50 h-full bg-white shadow-lg transition-transform duration-500 ease-in-out", {
                          "translate-x-0": isRendered,
                          "translate-x-full": !isRendered
                      })}
                  >
                      {canResize && isRendered && (
                          <div className="absolute top-0 left-0 bottom-0 z-50 flex w-1 cursor-ew-resize p-1" onMouseDown={onMouseDown} />
                      )}
                      <div className="flex h-full flex-col">
                          <Header onClose={onClose} navigation={header.navigation} {...header} />
                          <Separator orientation="horizontal" />
                          <div className="flex w-full flex-auto overflow-hidden p-4">{children}</div>
                      </div>
                  </div>
              </div>,
              document.body
          )
        : null;
}
