import { FullAudit } from "@vaultinum/vaultinum-api";
import filesize from "filesize";
import { CommonLang } from "../CommonLang";

const locale = "fr-FR";
const formatSize = (size = 0): string => filesize(size, { base: 10, locale });

/* eslint max-len: 0 */
export const frCommon: CommonLang = {
    code: "fr",
    getLang: "Français",
    apps: { name: "Applications" },
    shared: {
        // shared between authentication, account and apps
        email: "Email",
        copy: "Copier",
        invalidEmail: "Email invalide",
        name: "Nom",
        description: "Description",
        confirm: "Confirmer",
        cancel: "Annuler",
        add: "Ajouter",
        save: "Sauvegarder",
        edit: "Modifier",
        change: "Changer",
        remove: "Retirer",
        delete: "Supprimer",
        free: "Gratuit",
        next: "Suivant",
        previous: "Précédent",
        yes: "Oui",
        no: "Non",
        skip: "Ignorer",
        submit: "Valider",
        done: "Terminé",
        back: "Retour",
        unknown: "Inconnu",
        loading: "Chargement...",
        anyQuestion: "Des questions ?",
        contactUs: "Contactez-nous",
        requiredErrorMessage: "Ce champ est obligatoire",
        minErrorMessage: minLength => `Minimum ${minLength} caractères`,
        atLeastOneUpperLowercase: "Au moins une majuscule et une minuscule",
        atLeastOneSpecialCharacter: "Au moins un caractère spécial",
        atLeastOneDigit: "Au moins un chiffre",
        noConsecutivesCharacters: "Le mot de passe ne doit pas inclure 3 caractères consécutifs de votre adresse électronique",
        loginFailed: "La connexion a échoué.",
        logoutFailed: "La déconnexion a échoué.",
        saveSuccessMessage: "Sauvegardé avec succès",
        saveErrorMessage: "Échec de la sauvegarde",
        inviteSuccessMessage: "Invitation envoyée avec succès",
        inviteErrorMessage: "L'envoi de l'invitation a échoué",
        sendEmailSuccessMessage: "Email envoyé avec succès",
        sendEmailErrorMessage: "L'envoi de l'email a échoué",
        signIn: "Se connecter",
        invalidBirthdate: "Veuillez saisir une date de naissance correcte",
        dateFormat: "MM/JJ/AAAA",
        successMessage: "Succès",
        failMessage: "Échec",
        password: "Mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        everything: "Tout",
        updated: "Mis à jour",
        update: "Mettre à jour",
        apply: "Appliquer",
        logOutProgress: "Déconnexion...",
        emailMustBeDifferentFromYours: "L'adresse email doit être différente de la vôtre", // to review
        details: "Détails",
        uploadDescription: fullAudit => {
            if (fullAudit) {
                return "Déposez votre fichier manuellement";
            } else {
                return "Déposez un ou plusieurs fichiers, fichiers compressés ou dossiers";
            }
        },
        uploadMethod: "Méthode de dépôt",
        uploadTitle: "Ajouter",
        seeAllItems: appInfoLang => `Voir ${appInfoLang?.allItemsName || "tout"}`,
        overview: "Vue d'ensemble",
        selectedRepositories: {
            [1]: "1 répertoire sélectionné",
            plural: count => `${count} répertoires sélectionnés`
        },
        selectManually: "Sélection manuelle",
        upload: "Téléverser",
        processing: "En traitement",
        ready: "Prêt",
        noResult: "Aucun résultat",
        included: "Inclus",
        search: "Rechercher",
        results: {
            [0]: "0 résultat",
            [1]: "1 résultat",
            plural: count => `${count} résultats`
        },
        characters: {
            [0]: "0 caractère",
            [1]: "1 caractère",
            plural: count => `${count} caractères`
        },
        invalidPhoneNumber: "Numéro de téléphone invalide",
        other: "Autres",
        filterBy: (label: string) => `Filtrer par ${label.toLocaleLowerCase()}`,
        sortBy: (label: string) => `Trier par ${label.toLocaleLowerCase()}`,
        ascending: "Croissant",
        descending: "Décroissant",
        organisation: "Organisation",
        domain: "Domaine", // to review
        status: "Statut",
        empty: "vide",
        months: {
            january: "Janvier",
            february: "Février",
            march: "Mars",
            april: "Avril",
            may: "Mai",
            june: "Juin",
            july: "Juillet",
            august: "Août",
            september: "Septembre",
            october: "Octobre",
            november: "Novembre",
            december: "Décembre"
        },
        changeFilter: "Pour élargir votre recherche, changez ou supprimez le filtre ci-dessus",
        selectAccount: "Sélectionner un compte",
        failedFetchingAccount: "Erreur lors de la récupération du compte.",
        others: "Autres"
    },
    menu: {
        failedToGetMenuItems: "Une erreur s'est produite lors du chargement du menu"
    },
    date: {
        on: "le",
        doneOn: isFeminine => (isFeminine ? "Faite le" : "Fait le"),
        createdOn: isFeminine => (isFeminine ? "Créée le" : "Créé le"),
        updatedOn: isFeminine => (isFeminine ? "Mise à jour le" : "Mis à jour le"),
        completedOn: isFeminine => (isFeminine ? "Terminée le" : "Terminé le"),
        requestedOn: isFeminine => (isFeminine ? "Envoyée le" : "Envoyé le"),
        addedOn: isFeminine => (isFeminine ? "Ajoutée le" : "Ajouté le")
    },
    settingsMenu: {
        signOut: "Se déconnecter",
        signedInAs: "Connecté en tant que :",
        companyAccount: "Organisation sélectionnée :",
        settings: "Paramètres",
        profileSettings: "Paramètres du profil",
        accountSettings: "Paramètres de l'organisation",
        switchTo: "Changer l'organisation sélectionnée :",
        manageAccounts: "Gérer mes organisations",
        newOrganization: "Nouvelle organisation"
    },
    price: {
        allPlans: "Tous les Formules",
        priceOnDemand: "Prix sur Devis",
        getStarted: "Commencer",
        title: {
            accessClause: "Clause d'Accès",
            bipartite: "Contrat Bipartite",
            tripartite: "Contrat Tripartite",
            deposit: "Dépôt",
            depositUpdate: "Mise à jour du dépôt",
            depositUnlimitedPlan: "Formule Dépôt Illimité",
            elementCheckedDeposit: "Dépôt Vérifié",
            contentCheckedDeposit: "Dépôt Contrôlé",
            oneShot: "One Shot"
        },
        features: {
            fiveSurveys: "Cinq questionnaires",
            multiUserAccess: "Accès multi-utilisateurs",
            illustratedReport: "Rapport illustré",
            recommendations: "Recommandations",
            customerSupport: "Support client",
            oneSurvey: "Un questionnaire",
            unlimitedNumberOfSurvey: "Questionnaires illimités",
            encryptedDeposit: "Dépôt chiffré",
            certificateOfDeposit: "Certificat de dépôt",
            freeUpdates: "Mises à jour gratuites",
            contractTemplate: "Modèle de contrat",
            legalTermsAtYourService: "Une équipe juridique à votre service",
            beneficiaryDashboardAccess: "Accès au tableau de bord pour les bénéficiaires",
            vaultinumAsSignator: "Vaultinum en tant que signataire",
            oneOnlineAssessment: "Auto-Certification",
            sourceCodeScan: "Scanning du Source Code",
            expertAnalysis: "Analyse d'Expert",
            multipleProducts: "Multi-projets",
            multipleCompanies: "Multi-sociétés"
        }
    },
    payment: {
        checkout: {
            name: "Checkout"
        },
        proceed: "Procéder au paiement",
        downloadReceipt: "Télécharger le reçu",
        downloadReceiptError: "Une erreur est survenue lors du téléchargement du reçu",
        payment: {
            price: "Prix",
            promoCode: "Code promo",
            promoCodePlaceholder: "Ex: GPBM6PYXE",
            promoCodeError: "Ce code promo n'est pas valide",
            taxes: taxValue => `Taxes (${taxValue}% TVA)`,
            name: "Paiement",
            discount: "Remise",
            total: "Total",
            message: "Choisissez votre mode de paiement :",
            creditCard: {
                name: "Carte de crédit ou de débit",
                cardName: "Nom de la carte",
                saveAcceptance:
                    "En cochant cette case, vous acceptez de sauvegarder ce mode de paiement afin qu'il vous soit proposé lors de vos prochaines commandes. Toutefois, ce mode de paiement ne remplacera pas votre mode de paiement actuel par défaut."
            },
            sepaDebit: {
                name: "Débit SEPA",
                accountHolder: {
                    name: "Nom du titulaire du compte",
                    namePlaceholder: "Nom complet"
                },
                iban: "IBAN",
                mandateAcceptance:
                    "En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) Vaultinum et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte et (B) à votre banque de débiter votre compte conformément à ces instructions. Dans le cadre de vos droits, vous avez droit à un remboursement de la part de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans un relevé que vous pouvez obtenir auprès de votre banque. Vous acceptez de recevoir des notifications pour les futurs débits jusqu'à 2 jours avant qu'ils ne surviennent."
            },
            activationCode: {
                name: "Code d'activation",
                message: "Entrez votre code d'activation",
                placeholder: "Code d'activation..."
            },
            method: "Méthode de paiement"
        },
        done: {
            name: "Terminé",
            message: "Votre achat s'est réalisé avec succès !"
        },
        failed: {
            name: "Échec",
            message: "Une erreur est survenue lors du paiement, merci de bien vouloir réessayer.",
            backToPayment: "Retour au paiement",
            unableToConfirmCard: "Impossible de confirmer la carte",
            callbackError:
                "Il y a eu un problème lors de la finalisation du paiement. Vous avez été facturé. Si vous essayez de payer à nouveau, vous risquez d'être facturé deux fois. Veuillez nous contacter pour que nous puissions vous donner un coupon gratuit avant de réessayer."
        },
        billing: {
            address1: "Adresse",
            address: "Adresse de facturation",
            billingInformation: "Coordonnées de facturation",
            city: "Ville",
            country: "Pays",
            companyName: "Nom de l'entreprise",
            vatNumber: "Numéro de TVA",
            vatNumberPlaceholder: "FRAB123456789",
            wrongVatFormat: "Le numéro de TVA est invalide",
            taxIdRegistered: "Numéro de TVA déjà enregistré",
            notProvided: "Non fourni",
            zipCode: "Code postal",
            billingDisclaimer: "Veuillez vérifier l'exactitude de vos coordonnées de facturation.",
            formWarningMissingDetails: "Certaines coordonnées de facturation sont manquantes",
            billingContact: "Contact pour la facturation",
            billingContactPlaceholder: "Entrez l'email du contact pour la facturation"
        },
        frequency: {
            day: "jour",
            week: "semaine",
            month: "mois",
            year: "an"
        },
        summary: {
            order: "Récapitulatif de la commande",
            billingAddress: "Adresse de facturation",
            paymentMethod: "Mode de paiement",
            disclaimer: "Vous recevrez un email de confirmation avec les détails de la commande"
        },
        method: {
            expired: "Expirée",
            addPaymentMethod: "Ajouter une méthode de paiement",
            expirationDate: "Date d'expiration",
            errorListingPaymentMethods: "Une erreur s'est produite lors de la récupération de la liste des modes de paiement",
            errorAddingPaymentMethod: "Une erreur s'est produite lors de l'ajout du moyen de paiement"
        }
    },
    industryList: {
        businessConsultancyManagement: "Affaires, conseil ou gestion",
        accountancyBankingFinance: "Comptabilité, banque ou finance",
        energyUtilities: "Énergie et services publics",
        engineeringManufacturing: "Ingénierie ou fabrication",
        environmentAgriculture: "Environnement ou agriculture",
        softwarePublisher: "Éditeur de logiciels",
        iTServices: "Services informatiques",
        law: "Droit",
        lawEnforcementSecurity: "Application de la loi et sécurité",
        leisureHospitalityTourism: "Loisirs, hôtellerie ou tourisme",
        mediaDigitalPublicRelations: "Médias, numérique, relations publiques",
        propertyConstruction: "Immobilier ou construction",
        publicServicesAdministration: "Services publics ou administration",
        scientificResearch: "Recherche scientifique",
        pharmaceuticalsHealthcare: "Produits pharmaceutiques, soins de santé",
        education: "Éducation",
        transportLogistics: "Transport ou logistique",
        other: "Autres"
    },
    cookieConsent: {
        accept: "Accepter",
        decline: "Refuser",
        description:
            "Nous utilisons des cookies pour analyser le trafic et améliorer l'expérience de l'utilisateur. Vous pouvez lire notre avis juridique pour en savoir plus sur la façon dont nous traitons les données."
    },
    survey: {
        preamble: "Préambule",
        resetQuestion: "Réinitialiser la question",
        extraInformation: "Informations complémentaires...",
        pleaseDescribe: "(veuillez donner une description)",
        quickFix: "Correction rapide",
        longFix: "Correction longue",
        evaluationImpact: {
            critical: "Impact critique",
            high: "Impact élevé",
            low: "Impact faible"
        },
        priority: "Priorité",
        affects: "Affecte",
        status: "Statut",
        pending: "En attente",
        updated: "Mise à jour",
        obsolete: "Obsolète",
        updateAnswer: "Mettre à jour la réponse",
        updatedByReviewer: "Mis à jour par un expert de Vaultinum",
        updateAnswerError: "Une erreur est survenue lors de la mise à jour de la réponse",
        yourInitialAnswer: "Votre réponse initiale",
        initialAnswer: "Réponse initiale",
        updateYourCurrentAnswer: "Mettez à jour votre réponse actuelle",
        isObsolete: "Cette recommandation est maintenant obsolète",
        obsoleteReason: "Cette question n'existe plus sur la version actuelle du questionnaire",
        beforeStart: "Avant de commencer",
        domainExtension: "Extension de domaine",
        countryList: "Liste des pays...",
        trademarkClass: "Marque déposée...",
        filterQuestionWith: "Questions avec...",
        questionWithNotes: "Notes",
        questionWithRecommendations: "Recommandations",
        sections: "Sections",
        recommendations: "Recommandations"
    },
    report: {
        completed: "Effectué",
        industryAverage: "Moyenne du secteur",
        score: "Score"
    },
    fileTree: {
        lines: "Lignes",
        size: "Taille"
    },
    codeInput: {
        codeValidationMessage: "Vérification du code...",
        codeValidationMessageError: "Code invalide, veuillez réessayer.",
        codeValidationMessageSuccess: "Code valide."
    },
    register: {
        acceptTermsConditionsAndPrivacyPolicyErrorMessage: "Les Conditions Générales d'Utilisation et la Politique de confidentialité doivent être acceptées.",
        alreadyRegistered: "Déjà membre ?",
        confirmPasswordErrorMessage: "Les mots de passe ne coïncident pas",
        createAccount: "Création de compte",
        iAcceptThe: "J'accepte les",
        join: company => `Rejoindre ${company}`,
        login: "Se connecter",
        logout: "Déconnexion",
        privacyPolicy: "la Politique de confidentialité",
        register: "S'inscrire",
        registerWelcome: "Veuillez renseigner les champs ci-dessous afin de créer un compte.",
        termsConditions: "Conditions Générales d'Utilisation de la Plateforme",
        failMessage: "Une erreur est survenue lors de l'inscription."
    },
    onboarding: {
        title: "Bienvenue sur Vaultinum !",
        subtitle: "Tiers de confiance indépendant spécialisé dans la protection et l'audit des actifs numériques."
    },
    client: {
        supplier: "Fournisseur",
        beneficiary: "Bénéficiaire"
    },
    fullAudit: {
        errors: {
            notFound: "Due Diligence introuvable."
        },
        status: {
            [FullAudit.Status.OPEN]: "Ouvert",
            [FullAudit.Status.IN_PROGRESS]: "En cours",
            [FullAudit.Status.BLOCKED]: "Bloqué",
            [FullAudit.Status.CANCELLED]: "Annulé",
            [FullAudit.Status.DONE]: "Terminé"
        }
    },
    parties: {
        newParty: "Ajouter une partie",
        sendInvitation: "Envoyer une invitation",
        sendInvitationByEmail: "Envoyer l'invitation par email",
        inputSupplierEmail: "Veuillez indiquer l'email de votre contact :",
        invitation: companyName =>
            `Invitez votre contact à se connecter à “${companyName}” et commencez à collaborer sur des audits et/ou entiercements. Votre nouvelle partie pourra également consulter le nom, l'adresse et le numéro d'enregistrement de votre entreprise. Si votre contact n'a pas encore de compte Vaultinum, il sera invité à en créer un. Cochez la case ci-dessous si vous souhaitez les notifier par email.`,
        alreadyPartner: "Cette adresse email est déjà partenaire de votre organisation",
        alreadyReceivedInvitation: "Cette adresse email a déjà reçu une invitation",
        alreadySentInvitation: "Une invitation a déjà été envoyée à cette adresse email",
        sendInvitationConfirmation: (email: string) => `Etes vous sûr de vouloir envoyer l'invitation au mail suivant : ${email}`
    },
    accountInformation: {
        title: "Informations sur l'organisation",
        form: {
            companyName: "Nom de l'organisation",
            companyRegistrationNumber: "Numéro d'enregistrement",
            companyNationality: "Nationalité",
            companyIndustry: "Nom du secteur d'activité",
            companySize: "Taille de l'organisation",
            address: {
                title: "Adresse de l'organisation",
                line1: "Adresse",
                line1PlaceHolder: "Numéro, nom de la rue",
                line2: "Ligne d'adresse 2",
                line2PlaceHolder: "Bâtiment, étage",
                city: "Ville",
                postalCode: "Code Postal",
                postalCodePlaceHolder: "00000",
                country: "Pays"
            },
            billing: {
                invoiceCompanyName: "Nom de l'entreprise facturée",
                billingInformationInvoiceCompanyName:
                    "Par défaut, le nom de votre entreprise est affiché sur votre facture. Si vous souhaitez afficher un nom personnalisé à la place, saisissez-le ici :",
                billingInformationInvoicePostalAddress: "Si vous souhaitez ajouter une adresse postale différente à chaque facture, saisissez-la ici",
                billingInformationQuestions: "Des questions sur la facturation ?"
            }
        },
        roleAdminIsRequired: "Vous devez être administrateur ou mandataire pour modifier les informations de l'entreprise",
        notes: {
            title: "Notes",
            deleteNote: "Supprimer la note",
            showNotes: "Afficher les notes",
            permanentlyDeleteNote: "Ceci supprimera définitivement la note",
            visibleBy: "Visible par",
            updatedBy: "Dernière mise à jour par",
            reviewers: "évaluateurs",
            membersOf: accountName => `les membres de ${accountName}`
        }
    },
    seo: {
        registerTitle: "Inscrivez-vous sur la plateforme de gestion | Vaultinum", // to review
        registerDescription:
            "Inscrivez-vous sur notre plateforme online | Effectuez un dépôt de PI instantané, gérez vos software escrow, menez une due diligence technologique | ISO27001", // to review
        loginTitle: "Connectez-vous à la plateforme de gestion online | Vaultinum", // to review
        loginDescription:
            "Connectez-vous à notre plateforme online et effectuez un dépôt de PI instantané, gérez vos software escrow ou menez une due diligence technologique | ISO27001", // to review
        homeTitle: "Quelle action voulez-vous effectuer ? | Vaultinum", // to review
        homeDescription:
            "Notre plateforme permet aux utilisateurs d'effectuer un dépôt de PI, de gérer un software escrow ou d'effectuer une due diligence technologique | ISO27001" // to review
    },
    git: {
        gitConnection: "Connexion Git",
        notConfigured: "Il semble que vous n'ayez pas encore établi de connexion Git",
        description: fullAudit => {
            if (fullAudit) {
                return "Téléchargez votre code directement à partir d'une connexion Git";
            } else {
                return "Synchronisez vos répertoires de code à intervalles réguliers ou selon des événements git";
            }
        },
        notConfiguredDetails:
            "Pour configurer votre gestionnaire de version (GitHub par exemple), cliquez sur le bouton ci-dessous et suivez les instructions. Revenez ici lorsque ce sera terminé.",
        selectSource: "Sélectionner la source",
        selectRepository: "Sélectionner le/les répertoires de code",
        selectBranch: "Sélectionner la branche",
        selectFrequency: "Sélectionner une fréquence",
        defaultBranch: "par défaut",
        frequency: {
            weekly: "Hebdomadaire",
            monthly: "Mensuel",
            every3Month: "Tous les 3 mois",
            every6month: "Tous les 6 mois",
            oneShot: "Unique"
        },
        connectionActive: "Connexion git active",
        errors: {
            connectionSuspended: "Connexion git suspendue",
            connectionUninstalled: "Connexion git désinstallée",
            connectionNotAccessible: "Connexion git non accessible",
            repositoryNotAccessible: "Répertoire git non accessible",
            repositoryNotFound: "Répertoire git non trouvé",
            branchDeleted: "Branche supprimée",
            archiveSizeExceeded: "La taille de l'archive dépasse la limite",
            operationFailed: "Opération git échouée"
        },
        addRepository: "Ajouter un répertoire de code",
        addAllRepositories: "Ajouter tous les répertoires de code",
        branch: "Branche",
        selectedRepositories: "Répertoires de code sélectionnés",
        warningCheckoutConfirmation: "Une fois confirmé, vous ne pourrez plus modifier le titre, la description et les répertoires de code sélectionnés",
        frequencyTitle: "Fréquence",
        summaryError: "Une erreur est survenue lors de la récupération des informations",
        codeRepository: "Répertoire de code",
        source: "Source",
        repositoriesError: "Une erreur est survenue lors de la récupération des répertoires de code",
        setup: "Configurer",
        codeRepositoryEmpty: "Veuillez ajouter des répertoires de code et sélectionner une branche"
    },
    table: {
        apply: "Appliquer",
        search: "Rechercher",
        filter: "Filtrer",
        sort: "Trier",
        selectAll: "Tout sélectionner",
        clearAll: "Tout effacer",
        sortAsc: "Trier par ordre croissant",
        sortDesc: "Trier par ordre décroissant"
    },
    email: {
        subject: {
            billingSupportRequest: "Demande de support - facturation",
            supportRequest: "Demande de support"
        }
    },
    uploadFile: {
        description: (fileType: string) => `Déposez ou sélectionnez votre fichier au format ${fileType}`,
        file: "Fichier",
        size: "Taille",
        noFileUploaded: "Aucun fichier déposé",
        preview: "Aperçu",
        formatSize,
        tooManyFiles: "Un seul fichier est autorisé",
        invalidFileType: (fileName: string, fileType: string) => `Le fichier ${fileName} doit être au format suivant : ${fileType}`,
        acceptedFileTypes: (fileTypes: string) => `Seulement les types de fichiers suivants sont acceptés : ${fileTypes}`,
        actions: "Actions",
        minResolution: (resolution: number) => `Taille minimale recommandée : ${resolution}px (largeur/hauteur)`,
        errorFetchingFiles:
            "Une erreur s'est produite lors de la récupération de fichiers depuis notre serveur. Veuillez nous contacter pour résoudre ce problème avant d'essayer d'importer des fichiers.",
        errorWhileUploading: "Erreur lors de l'importation de votre fichier. Veuillez contacter notre service client.",
        files: "Fichiers",
        filesAlreadyExistError: files => `${files} existent déjà`,
        maxFileSizeError: (filename, maxFileSize) => `${filename} est plus grand que ${maxFileSize}`,
        maxNumberOfFilesError: maxNumberOfFiles => `Vous ne pouvez pas télécharger plus de ${maxNumberOfFiles} fichiers`,
        maxTotalFileSizeError: maxTotalFileSize => `Vous ne pouvez pas télécharger un ensemble de fichiers de plus de ${maxTotalFileSize}`,
        minNumberOfFilesError: "Vous devez télécharger au moins un fichier",
        noFilesUploadedYet: "Aucun fichier téléchargé pour le moment",
        notPossibleToDelete: filename => `Il n'a pas été possible de supprimer ${filename}`,
        progress: "Progression",
        uploadInstructions: "Faites glisser et déposez vos fichiers ici, ou cliquez pour sélectionner des fichiers",
        uploadLimitations: (acceptedFileTypes, maxFileSize, maxTotalSize) =>
            `${
                !acceptedFileTypes?.length
                    ? "Tous les types de fichiers sont acceptés"
                    : `Seuls certains types de fichiers sont acceptés : ${acceptedFileTypes}`
            }. Un seul fichier ne peut pas dépasser ${maxFileSize}. La taille totale ne peut pas excéder ${maxTotalSize}.`,
        waitUntilFinished: "Vous devez attendre la fin du téléchargement"
    },
    chart: {
        monthAbbreviation: "m",
        yearAbbreviation: "a"
    }
};
