import { AccountInvitation } from "@vaultinum/vaultinum-api";
import {
    AppCode,
    AppLayout,
    CheckCircleIconAnimated,
    ContentLoader,
    plural,
    ResultFeedback,
    Stepper,
    useAuthContext,
    useLang,
    useWhiteLabelContext
} from "@vaultinum/vaultinum-sdk";
import { useMachine } from "@xstate/react";
import { useEffect, useMemo, useState } from "react";
import { getPrioritaryUrl } from "../../../helpers";
import { AccountLang } from "../../../lang/AccountLang";
import {
    CreateAs,
    getPendingInvitationsByEmailAndDomainId,
    InvitationsFields,
    OrganisationInformationsFields,
    URL,
    UserProfileFields
} from "../../../services";
import CreateAsStep from "./CreateAsStep";
import InvitationsStep from "./InvitationsStep";
import { createOnboardingMachine, OnboardingWorkflow } from "./onboardingMachine";
import OrganisationCollaboratorsStep from "./OrganisationCollaboratorsStep";
import OrganisationInformationsStep from "./OrganisationInformationsStep";
import UserProfileStep from "./UserProfileStep";

function formatStep(step: string, lang: AccountLang, isRepresentative: boolean): string {
    switch (step) {
        case "userProfile":
            return lang.onboarding.userProfile.title;
        case "invitations":
            return lang.onboarding.invitations.title;
        case "createAs":
            return lang.onboarding.account.createAs.title;
        case "organisationInformations":
            return lang.onboarding.account.accountInfo.title(isRepresentative);
        case "organisationCollaborators":
            return lang.onboarding.account.collaborate.title(isRepresentative);
        default:
            return "";
    }
}

const ORGANISATION_STEPS = ["organisationInformations", "organisationCollaborators"];

function getSteps(workflow: OnboardingWorkflow, hasAccounts: boolean, hasInvitations: boolean, isCreatingOrganisation: boolean): string[] {
    if (workflow === OnboardingWorkflow.ADD_ACCOUNT) {
        return ["createAs", ...ORGANISATION_STEPS];
    }
    if (hasAccounts) {
        return ["userProfile"];
    }
    if (!hasInvitations) {
        return ["userProfile", ...ORGANISATION_STEPS];
    }
    if (!isCreatingOrganisation) {
        return ["userProfile", "invitations"];
    }
    return ORGANISATION_STEPS;
}

export default function OnboardingPage({ workflow }: { workflow: OnboardingWorkflow }): JSX.Element {
    const lang = useLang<AccountLang>();
    const { user, userProfile } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const [invitations, setInvitations] = useState<AccountInvitation[] | undefined>(undefined);

    useEffect(() => {
        if (workflow === OnboardingWorkflow.ADD_ACCOUNT) {
            setInvitations([]);
            return () => {};
        }
        if (userProfile?.email) {
            return getPendingInvitationsByEmailAndDomainId(userProfile.email, whiteLabelDomain?.id, setInvitations);
        }
        return () => {};
    }, [workflow, userProfile?.email, whiteLabelDomain?.id]);

    useEffect(() => {
        // Handle redirection at page load to avoid sudden auto-redirection by router once /welcome is not anymore a prioritary url
        if (getPrioritaryUrl(user, userProfile) !== URL.welcome && workflow === OnboardingWorkflow.NO_ACCOUNT) {
            return window.location.assign(URL.index);
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onboardingMachine = useMemo(
        () =>
            createOnboardingMachine(workflow).withContext({
                userProfileFields: userProfile || ({} as UserProfileFields),
                invitationsFields: { selectedInvitations: [] } as InvitationsFields,
                createAsFields: { createAs: CreateAs.ORGANISATION_MEMBER },
                organisationInformationsFields: {} as OrganisationInformationsFields,
                collaboratorsFields: {
                    emails: [{ email: "" }],
                    shouldNotifyByEmail: true
                },
                selectedAppCode: userProfile?.selectedAppCode as unknown as AppCode,
                invitations,
                accountsCount: userProfile?.accountIds?.length ?? 0,
                acceptedInvitationsCount: 0
            }),
        [workflow, userProfile, invitations]
    );

    const [state, send] = useMachine(onboardingMachine);

    useEffect(() => {
        send({ type: "updateSelectedAppCode", value: userProfile?.selectedAppCode as unknown as AppCode });
    }, [send, userProfile]);

    useEffect(() => {
        send({ type: "updateInvitations", value: invitations });
    }, [send, invitations]);

    const isRepresentative = state.context.createAsFields.createAs === CreateAs.REPRESENTATIVE;

    const steps = useMemo(
        () =>
            getSteps(
                workflow,
                !!userProfile?.accountIds?.length,
                !!invitations?.length,
                ORGANISATION_STEPS.some(step => state.matches(step))
            ).map(step => ({ key: step, title: formatStep(step, lang, isRepresentative) })),
        [workflow, userProfile?.accountIds?.length, invitations?.length, state, lang, isRepresentative]
    );

    const doCancel = () => send({ type: "cancel" });
    const doBack = () => send({ type: "back" });
    const doNext = () => send({ type: "next" });

    const isFeedbackStep = state.matches("organisationSuccessFeedback") || state.matches("invitationsSuccessFeedback");

    return (
        <AppLayout layout={workflow === OnboardingWorkflow.NO_ACCOUNT ? "minimal" : "full"} isMenuDefaultExpanded={false} hideExpandButton>
            <ContentLoader loading={!invitations}>
                <div className="space-y-8">
                    {workflow === OnboardingWorkflow.NO_ACCOUNT && !isFeedbackStep && (
                        <div className="flex flex-col items-center gap-4 justify-center text-slate-primary">
                            <div className="text-4xl font-bold">{lang.onboarding.title}</div>
                            {lang.onboarding.subtitle}
                        </div>
                    )}
                    {!isFeedbackStep && steps.length > 1 && <Stepper steps={steps} currentStepKey={state.value as string} />}
                    {state.matches("userProfile") && (
                        <UserProfileStep
                            context={state.context}
                            onChange={value => send({ type: "updateUserProfileFields", value })}
                            canDoNext={state.can({ type: "next" })}
                            doNext={doNext}
                        />
                    )}
                    {state.matches("userProfileSuccessFeedback") && (
                        <div className="mx-auto max-w-2xl w-max">
                            <ResultFeedback
                                title={lang.onboarding.userProfile.successOnSave}
                                icon={CheckCircleIconAnimated}
                                type="info"
                                buttonProps={{
                                    children: lang.shared.done,
                                    isLoading: false,
                                    onClick: doNext
                                }}
                                containerDataId="user-profile-success-feedback"
                            />
                        </div>
                    )}
                    {state.matches("invitations") && (
                        <InvitationsStep
                            context={state.context}
                            onChange={value => send({ type: "updateInvitationsFields", value })}
                            doBack={doBack}
                            doNext={acceptedInvitationsCount => {
                                send({ type: "updateAcceptedInvitationsCount", value: acceptedInvitationsCount });
                                doNext();
                            }}
                        />
                    )}
                    {state.matches("invitationsSuccessFeedback") && (
                        <div className="mx-auto max-w-2xl w-max">
                            <ResultFeedback
                                title={plural(lang.onboarding.invitations.successOnJoin, state.context.acceptedInvitationsCount)}
                                icon={CheckCircleIconAnimated}
                                type="info"
                                buttonProps={{
                                    children: lang.shared.done,
                                    isLoading: false,
                                    onClick: doNext
                                }}
                                containerDataId="invitations-success-feedback"
                            />
                        </div>
                    )}
                    {state.matches("createAs") && (
                        <CreateAsStep
                            context={state.context}
                            onChange={value => send({ type: "updateCreateAsFields", value })}
                            doCancel={doCancel}
                            canDoNext={state.can({ type: "next" })}
                            doNext={doNext}
                        />
                    )}
                    {state.matches("organisationInformations") && (
                        <OrganisationInformationsStep
                            context={state.context}
                            onChange={value => send({ type: "updateOrganisationInformationsFields", value })}
                            doBack={doBack}
                            canDoNext={state.can({ type: "next" })}
                            doNext={doNext}
                        />
                    )}
                    {state.matches("organisationCollaborators") && (
                        <OrganisationCollaboratorsStep
                            context={state.context}
                            onChange={value => send({ type: "updateOrganisationCollaboratorsFields", value })}
                            doBack={doBack}
                            doNext={doNext}
                        />
                    )}
                    {state.matches("organisationSuccessFeedback") && (
                        <div className="mx-auto max-w-2xl w-max">
                            <ResultFeedback
                                title={lang.onboarding.account.successOnSave(state.context.createAsFields.createAs === CreateAs.REPRESENTATIVE)}
                                icon={CheckCircleIconAnimated}
                                type="info"
                                buttonProps={{
                                    children: lang.shared.done,
                                    isLoading: false,
                                    onClick: doNext
                                }}
                                containerDataId="organisation-success-feedback"
                            />
                        </div>
                    )}
                </div>
            </ContentLoader>
        </AppLayout>
    );
}
