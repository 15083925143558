import {
    ColumnFiltersState,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    Row,
    SortingState,
    useReactTable,
    VisibilityState
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isDefined } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { differenceBy, isEqual, partition, sum } from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import { FiltersSortLocalStorageService, TableStorageKey } from "../../../services";
import { Color } from "../../referentials";
import { Buttons } from "../Button";
import { ChevronDownDoubleIcon, ChevronRightDoubleIcon } from "../Icons";
import { Spin } from "../Spin";
import { Body, VirtualizedBody } from "./Body";
import { ExpandRowButton } from "./ExpandRowButton";
import { Head } from "./Head";
import { SelectAllHeader, SelectRowCheckbox } from "./SelectRowCheckbox";
import { Column, EMPTY_VALUES, EmptyFilter, isExpandableRow, TableRow } from "./TableTools";

const DEFAULT_COLUMN_SIZE = 200;
const TABLE_CLIENT_WIDTH_OVERLAP = 13;

function isRecord(value: unknown): value is Record<string, unknown> {
    return value !== null && typeof value === "object" && !!Object.keys(value).length;
}

declare module "@tanstack/table-core" {
    interface FilterFns {
        multiSelectFilter: (row: Row<unknown>, columnId: string, filterValue: unknown, columns: Column[]) => boolean; // custom filter
    }
}

function multiSelectFilter<T extends object>(row: Row<T>, columnId: string, value: unknown[], columns: Column<T>[]): boolean {
    const filteredValue = row.getValue(columnId);
    const col = columns.find(column => [column.accessorKey, column.id, column.header].includes(columnId));
    return (
        !!value?.length &&
        !!value.filter(val => {
            // Filter applied on empty values
            if (EMPTY_VALUES.includes(val as EmptyFilter)) {
                return EMPTY_VALUES.includes(filteredValue as EmptyFilter);
            }
            if (filteredValue === val) {
                return true;
            }
            // Special case where a cell value is typed as { [key: string]: boolean | string }
            if (isRecord(filteredValue) && !!filteredValue[val as string]) {
                return true;
            }
            if (Array.isArray(filteredValue)) {
                return filteredValue.includes(val);
            }
            if (col?.filters?.length) {
                return col.filters.every(filter => [true, val].includes(filter.onFilter(row.original)));
            }
            return false;
        }).length
    );
}

function ExpandHeader({ areRowsExpanded, onClick }: { areRowsExpanded: boolean; onClick: () => void }): JSX.Element {
    return (
        <div className="flex items-center justify-center" onClick={onClick}>
            <Buttons.Icon type="default" fill="link" isLoading={false} icon={areRowsExpanded ? ChevronDownDoubleIcon : ChevronRightDoubleIcon} color="slate" />
        </div>
    );
}

export enum SelectionMode {
    ROW = "row",
    CHECKBOX = "checkbox"
}

export type TableProps<T extends object> = {
    data: TableRow<T>[];
    columns: Column<T>[];
    searchText?: string;
    isVirtualized?: boolean;
    storageKey?: TableStorageKey;
    expandedRows?: Record<string, boolean>;
    selectedRows?: string[];
    setSelectedRows?: Dispatch<SetStateAction<string[]>>;
    onFilter?: (count: number) => void;
    onLoadChildren?: (row: T) => Promise<T[]>;
    itemKey?: keyof T;
    color?: Color;
    selectionMode?: SelectionMode;
    clearSelection?: boolean;
    setClearSelection?: Dispatch<SetStateAction<boolean>>;
};

function expandRows<T>(rows: Row<T>[]): ExpandedState {
    const result: ExpandedState = {};
    for (const row of rows) {
        result[row.id] = true;
    }
    return result;
}

export function Table<T extends object>({
    data,
    columns,
    searchText,
    expandedRows,
    selectedRows,
    isVirtualized,
    storageKey,
    setSelectedRows,
    onFilter,
    onLoadChildren,
    isTree,
    itemKey,
    color,
    selectionMode,
    clearSelection,
    setClearSelection
}: TableProps<T> & { isTree?: boolean }): JSX.Element {
    const [tableData, setTableData] = useState<TableRow<T>[]>(data);
    const [columnSize, setColumnSize] = useState(0);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [isReady, setIsReady] = useState(false);
    const [expanded, setExpanded] = useState<ExpandedState>(expandedRows ?? {});
    const [rowSelection, setRowSelection] = useState(selectedRows ?? {});
    const [areRowsExpanded, setAreRowsExpanded] = useState<boolean>(false);

    const filtersSortService = storageKey ? new FiltersSortLocalStorageService(storageKey) : undefined;
    useEffect(() => {
        setSelectedRows?.(Object.keys(rowSelection));
    }, [rowSelection]);

    useEffect(() => {
        if (clearSelection) {
            setRowSelection({});
            setClearSelection?.(false);
        }
    }, [clearSelection]);

    // useEffect needed as it seems that the columnSize is not set when the table is rendered the first time
    useEffect(() => {
        const defaultFilters = columns
            .filter(column => (Array.isArray(column.defaultFilteredValues) ? !!column.defaultFilteredValues?.length : !!column.defaultFilteredValues))
            .map(column => ({
                id: column.id || column.accessorKey || column.header?.toString() || "",
                value: Array.isArray(column.defaultFilteredValues) ? column.defaultFilteredValues : [column.defaultFilteredValues]
            }));
        if (filtersSortService) {
            const storedSorts = filtersSortService.loadSorts() ?? {};
            const existingSorts = Object.entries(storedSorts)
                .map(([key, value]) => (value ? { id: key, desc: storedSorts[key] === "desc" } : null))
                .filter(isDefined);
            if (existingSorts.length) {
                setSorting(existingSorts);
            }
            const storedFilters = filtersSortService.loadFilters() ?? {};
            const existingFilters = Object.entries(storedFilters).map(([key, value]) => ({
                id: key,
                value: value.map(val => (val === null ? undefined : val))
            }));
            // Merge default filters with already stored filters and remove empty filters (i.e defaultFilters overriden by user)
            setColumnFilters(
                [...(columnFilters ?? []), ...existingFilters, ...differenceBy(defaultFilters, existingFilters, "id")].filter(
                    filter => !!(filter?.value as unknown[])?.length
                )
            );
            setIsReady(true);
            return;
        }
        setSorting(
            columns
                .filter(column => !!column.defaultSort)
                .map(column => ({ id: column.id || column.accessorKey || column.header?.toString() || "", desc: column.defaultSort === "desc" }))
        );
        setColumnFilters([...(columnFilters ?? []), ...defaultFilters]);
        setIsReady(true);
    }, [columnSize]);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    const columnVisibility = columns
        .filter(column => !!column.hide)
        .reduce((acc, column) => {
            acc[column.id || column.accessorKey || column.header?.toString() || ""] = false;
            return acc;
        }, {} as VisibilityState);

    function findLeaf(rows: TableRow<T>[], parent: TableRow<T>, keyPath?: keyof TableRow<T>): TableRow<T> | null {
        let result = null;
        for (const item of rows) {
            if ((keyPath && item[keyPath] === parent[keyPath]) || isEqual(item, parent)) {
                return item;
            }
            if (item.children) {
                result = findLeaf(item.children, parent, keyPath);
                if (result) {
                    return result;
                }
            }
        }
        return result;
    }

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const isExpandable = tableData.some(row => isExpandableRow(row, isTree));
    const memoizedTableData = useMemo(() => [...(isExpandable ? tableData.map((row, index) => ({ expander: index, ...row })) : tableData)], [tableData]);
    const tableColumn = [
        ...(selectionMode === SelectionMode.CHECKBOX
            ? [
                  {
                      id: "selectAll",
                      accessorKey: "selectAll",
                      header: SelectAllHeader,
                      cell: SelectRowCheckbox,
                      size: 40,
                      enableSorting: false,
                      enableColumnFilter: false
                  }
              ]
            : []),
        ...(isExpandable && !isTree
            ? [
                  {
                      id: "expander",
                      accessorKey: "expander",
                      header: () => <ExpandHeader onClick={() => setAreRowsExpanded(prev => !prev)} areRowsExpanded={areRowsExpanded} />,
                      cell: ExpandRowButton,
                      size: 100,
                      enableSorting: false,
                      enableColumnFilter: false
                  }
              ]
            : []),
        ...columns
    ];

    const table = useReactTable<T & { children?: T[] }>({
        data: memoizedTableData ?? [],
        columns: tableColumn,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            globalFilter: searchText,
            expanded,
            rowSelection
        },
        filterFns: {
            multiSelectFilter: (row, columnId, filterValue) => multiSelectFilter<T>(row, columnId, filterValue, columns)
        },
        getColumnCanGlobalFilter: () => true, // Enable global filter on columns that contain data that is undefined or null
        enableColumnResizing: true,
        columnResizeMode: "onChange",
        defaultColumn: {
            filterFn: "multiSelectFilter",
            footer: props => props.column.id,
            minSize: 40,
            size: columnSize
        },
        meta: {
            selectionMode,
            setRowSelection: setSelectedRows ? setRowSelection : undefined,
            rowSelection,
            isTree,
            ...(onLoadChildren
                ? {
                      loadChildren: async (row: T) => {
                          const children = await onLoadChildren(row);
                          const parent = findLeaf(tableData, row, itemKey);
                          if (parent) {
                              parent.children = children;
                              setTableData([...tableData]);
                          }
                      }
                  }
                : {})
        },
        onExpandedChange: setExpanded,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        getRowCanExpand: row => isExpandableRow(row.original, isTree) || (!!isTree && !!row.original.children),
        getSubRows: row => row.children,
        filterFromLeafRows: true, // search in leaf rows and keep parents displayed
        ...(itemKey && { getRowId: row => row[itemKey] as string })
    });

    const tableRows = table?.getRowModel()?.rows || [];
    // Filter out subRows in case of simple table
    // SubRows are rendered by their render method
    const rows = isTree ? tableRows : tableRows.filter(row => !row.parentId);
    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        estimateSize: () => 30,
        count: rows.length,
        overscan: 20
    });

    // Count the number of rows displayed after filtering
    useEffect(() => {
        const filteredRowsCount = table.getFilteredRowModel().flatRows.reduce((count, row) => count + (row.subRows.length ? 0 : 1), 0);
        onFilter?.(filteredRowsCount);
    }, [searchText, columnFilters, tableColumn]);

    useEffect(() => {
        if (!searchText || !isTree) {
            return;
        }
        setExpanded(expandRows(table.getFilteredRowModel().flatRows));
    }, [searchText]);

    useEffect(() => {
        const handleResize = () => {
            const [fixedColumns, defaultColumns] = partition<Column<T>>(
                tableColumn.filter((col: Column<T>) => !col.hide),
                col => col.size
            );
            const viewportWidth = tableContainerRef.current?.clientWidth ?? 0;
            const totalFixedColumns = sum(fixedColumns.map(col => col.size));
            if (!viewportWidth) {
                return;
            }
            // calculate the size of the remaining columns
            // - get the viewport width
            // - subtract the overlap
            // - subtract the fixed size columns
            // - divide by the number of remaining columns
            const size = (viewportWidth - TABLE_CLIENT_WIDTH_OVERLAP - totalFixedColumns) / defaultColumns.length;
            setColumnSize(Math.max(size, DEFAULT_COLUMN_SIZE));
        };

        handleResize();

        // Add a listener on the window to handle the resize of the table
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tableContainerRef.current?.clientWidth]);

    useEffect(() => {
        rows.forEach(row => {
            const shouldExpand = areRowsExpanded && !row.getIsExpanded();
            const shouldCollapse = !areRowsExpanded && row.getIsExpanded();
            if (row.getCanExpand() && (shouldExpand || shouldCollapse)) {
                row.getToggleExpandedHandler()();
            }
        });
    }, [areRowsExpanded]);

    // Table is exclusively composed of div because it may have a beneficial impact on resizing virtualized tables
    // example: https://tanstack.com/table/latest/docs/framework/react/examples/column-resizing-performant
    // comment: https://github.com/TanStack/table/issues/3685
    return (
        <div
            ref={tableContainerRef}
            className={classNames("relative max-h-full w-full overflow-auto rounded-md", {
                "border bg-white": columnSize !== 0,
                [`border-${color}-light`]: !!color,
                "border-white": !color
            })}
        >
            {!isReady ? (
                <Spin />
            ) : (
                <div className="w-fit border-collapse">
                    <Head table={table} columns={columns} storageKey={storageKey} />
                    {isVirtualized ? <VirtualizedBody rows={rows} table={table} rowVirtualizer={rowVirtualizer} /> : <Body rows={rows} table={table} />}
                </div>
            )}
        </div>
    );
}
