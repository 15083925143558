import classNames from "classnames";
import { ElementType } from "react";
import { Color, COLOR_SCHEME, ColorShade, Size } from "../../referentials";
import {
    CodeSvg,
    CompanySvg,
    ComplexitySvg,
    CreditCardSvg,
    DuplicationSvg,
    FilesSvg,
    FileSvg,
    FolderSvg,
    LockSvg,
    NetworkSvg,
    OverviewSvg,
    RecommendationsSvg,
    ReportSvg,
    ScopeSvg,
    SourceCodeSvg,
    StackSvg,
    SurveySvg,
    SynchronizationSvg,
    ToggleSvg,
    UnplugSvg
} from "./application";
import { DomainsSvg, GithubSvg, GitSvg, HomeSvg, MastercardSvg, MicrosoftSvg, OfficeSvg, SepaSvg, VisaSvg } from "./business";
import {
    BarCircleSvg,
    CheckCircleFilledSvg,
    CheckCircleSvg,
    CheckCircleSvgAnimated,
    CheckSvg,
    ClockCircleSvg,
    CrossCircleFilledSvg,
    CrossCircleSvg,
    CrossCircleSvgAnimated,
    CrossSvg,
    InfoCircleSvg,
    QuestionInfoSvg,
    SwitchSvg,
    WarningFilledSvg,
    WarningSvg
} from "./information";
import {
    AppsSvg,
    BackArrowSvg,
    CandyBoxMenuSvg,
    ChevronDownDoubleSvg,
    ChevronDownSvg,
    ChevronLeftDoubleSvg,
    ChevronLeftSvg,
    ChevronRightDoubleSvg,
    ChevronRightSvg,
    ChevronUpDoubleSvg,
    ChevronUpSvg,
    ChevronUpTripleSvg,
    DownloadSvg,
    ExpandSvg,
    ExternalLinkSvg,
    FilterSortBgFilledSvg,
    FilterSortSvg,
    FunnelBgFilledSvg,
    FunnelFilledSvg,
    FunnelSvg,
    KeySvg,
    LinkSvg,
    MailSvg,
    SearchSvg,
    SortSvg,
    TableSvg,
    TrashSvg,
    UploadSvg,
    UsersSvg,
    UserSvg
} from "./system";

export const IconSize: Record<Exclude<Size, "xxs">, number> = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 32,
    xl: 48
};

export interface IconProps extends Pick<React.HTMLAttributes<HTMLSpanElement>, "onClick" | "title"> {
    color?: Color;
    shade?: ColorShade;
    size?: keyof typeof IconSize;
    isTwoToneColor?: boolean;
}

export type IconSvgProps = {
    size: (typeof IconSize)[keyof typeof IconSize];
    color?: Color;
};

export const customIconComponent =
    (CustomIconComponent: ElementType) =>
    ({ color, shade = "primary", size = "sm", ...props }: IconProps) => (
        <span
            {...props}
            className={classNames({
                "hover:cursor-pointer": !!props.onClick
            })}
            children={<CustomIconComponent color={color ? COLOR_SCHEME[color][shade] : "black"} size={IconSize[size]} />}
        />
    );

/**
 * Application Icons
 */
export const CodeIcon = customIconComponent(CodeSvg);
export const CompanyIcon = customIconComponent(CompanySvg);
export const ComplexityIcon = customIconComponent(ComplexitySvg);
export const CreditCardIcon = customIconComponent(CreditCardSvg);
export const FileCustomIcon = customIconComponent(FileSvg);
export const FilesIcon = customIconComponent(FilesSvg);
export const FolderCustomIcon = customIconComponent(FolderSvg);
export const LockIcon = customIconComponent(LockSvg);
export const NetworkIcon = customIconComponent(NetworkSvg);
export const OverviewIcon = customIconComponent(OverviewSvg);
export const RecommendationsIcon = customIconComponent(RecommendationsSvg);
export const ReportIcon = customIconComponent(ReportSvg);
export const ScopeIcon = customIconComponent(ScopeSvg);
export const SourceCodeIcon = customIconComponent(SourceCodeSvg);
export const StackIcon = customIconComponent(StackSvg);
export const SurveyIcon = customIconComponent(SurveySvg);
export const SynchronizationIcon = customIconComponent(SynchronizationSvg);
export const ToggleIcon = customIconComponent(ToggleSvg);
export const UnplugIcon = customIconComponent(UnplugSvg);
export const CodeDuplicationIcon = customIconComponent(DuplicationSvg);

/*
 * System Icons
 */
export const BackArrowIcon = customIconComponent(BackArrowSvg);
export const BarCircleIcon = customIconComponent(BarCircleSvg);
export const CandyBoxMenuIcon = customIconComponent(CandyBoxMenuSvg);
export const CheckIcon = customIconComponent(CheckSvg);
export const CheckCircleIcon = customIconComponent(CheckCircleSvg);
export const CheckCircleIconAnimated = customIconComponent(CheckCircleSvgAnimated);
export const CheckCircleFilledIcon = customIconComponent(CheckCircleFilledSvg);
export const ClockCircleIcon = customIconComponent(ClockCircleSvg);
export const CrossIcon = customIconComponent(CrossSvg);
export const CrossCircleIcon = customIconComponent(CrossCircleSvg);
export const CrossCircleIconAnimated = customIconComponent(CrossCircleSvgAnimated);
export const CrossCircleFilledIcon = customIconComponent(CrossCircleFilledSvg);
export const ChevronUpIcon = customIconComponent(ChevronUpSvg);
export const ChevronRightIcon = customIconComponent(ChevronRightSvg);
export const ChevronDownIcon = customIconComponent(ChevronDownSvg);
export const ChevronLeftIcon = customIconComponent(ChevronLeftSvg);
export const ChevronUpDoubleIcon = customIconComponent(ChevronUpDoubleSvg);
export const ChevronDownDoubleIcon = customIconComponent(ChevronDownDoubleSvg);
export const ChevronRightDoubleIcon = customIconComponent(ChevronRightDoubleSvg);
export const ChevronLeftDoubleIcon = customIconComponent(ChevronLeftDoubleSvg);
export const ChevronUpTripleIcon = customIconComponent(ChevronUpTripleSvg);
export const DownloadIcon = customIconComponent(DownloadSvg);
export const ExpandIcon = customIconComponent(ExpandSvg);
export const InfoCircleIcon = customIconComponent(InfoCircleSvg);
export const LinkIcon = customIconComponent(LinkSvg);
export const ExternalLinkIcon = customIconComponent(ExternalLinkSvg);
export const MastercardIcon = customIconComponent(MastercardSvg);
export const VisaIcon = customIconComponent(VisaSvg);
export const SepaIcon = customIconComponent(SepaSvg);
export const SortIcon = customIconComponent(SortSvg);
export const TrashIcon = customIconComponent(TrashSvg);
export const UploadIcon = customIconComponent(UploadSvg);
export const UserIcon = customIconComponent(UserSvg);
export const WarningIcon = customIconComponent(WarningSvg);
export const WarningFilledIcon = customIconComponent(WarningFilledSvg);
export const FilterSortIcon = customIconComponent(FilterSortSvg);
export const FilterSortBgFilledIcon = customIconComponent(FilterSortBgFilledSvg);
export const FunnelBgFilledIcon = customIconComponent(FunnelBgFilledSvg);
export const FunnelFilledIcon = customIconComponent(FunnelFilledSvg);
export const FunnelIcon = customIconComponent(FunnelSvg);
export const SearchIcon = customIconComponent(SearchSvg);
export const AppsIcon = customIconComponent(AppsSvg);
export const UsersIcon = customIconComponent(UsersSvg);
export const KeyIcon = customIconComponent(KeySvg);
export const QuestionInfoIcon = customIconComponent(QuestionInfoSvg);
export const TableIcon = customIconComponent(TableSvg);
export const MailIcon = customIconComponent(MailSvg);
export const SwitchIcon = customIconComponent(SwitchSvg);

/*
 * Business Icons
 */
export const DomainsIcon = customIconComponent(DomainsSvg);
export const MicrosoftIcon = customIconComponent(MicrosoftSvg);
export const HomeIcon = customIconComponent(HomeSvg);
export const GithubIcon = customIconComponent(GithubSvg);
export const OfficeIcon = customIconComponent(OfficeSvg);
export const GitIcon = customIconComponent(GitSvg);
