import { DEBUG_LANG, LANG_DE, LANG_EN, LANG_ES, LANG_FR, LANG_IT, SupportedLanguageCode, UserProfile } from "@vaultinum/vaultinum-api";
import { CommonLang } from "./CommonLang";
import { DEFAULT_LANG, SUPPORTED_LANGUAGES } from "./Languages";

/**
 * Retrieves Normalised Browser lang from the list of existing languages for navigator
 * https://gist.github.com/wpsmith/7604842
 */
export function getNormalisedBrowserLang(): SupportedLanguageCode {
    const browserLanguage = navigator.language.split("-")[0];
    return (
        (SUPPORTED_LANGUAGES.map(({ code }) => code).filter(code => code !== DEBUG_LANG) as SupportedLanguageCode[]).find(code =>
            browserLanguage.startsWith(code)
        ) ?? DEFAULT_LANG
    );
}

function keyPathAsValue<T extends CommonLang>(obj: T, parentPath?: string): T {
    return Object.entries(obj).reduce((pathObj, [key, value]): T => {
        const path = parentPath ? `${parentPath}.${key}` : key;
        if (typeof value === "string") {
            return { ...pathObj, [key]: path };
        }
        if (typeof value === "function") {
            return { ...pathObj, [key]: () => path };
        }
        return { ...pathObj, [key]: keyPathAsValue(value, path) };
    }, {} as T);
}

export function getLangCode(userProfile: UserProfile | null): SupportedLanguageCode {
    return (userProfile?.settings?.preferredLang as SupportedLanguageCode) ?? getNormalisedBrowserLang();
}

/**
 *
 * @param langCode given lang code for which we retrieve the entire translation
 * @param languages the map of implemented translations
 *
 * Exemple:
 * ```typescript
 * interface CustomLang extends CommonLang{}
 * const enLang: CustomLang = { shared: { name: "enTranslation" ...} };
 * const frLang: CustomLang = { shared: { name: "frTranslation" ...} };
 * const ...
 * const languagesMap = new Map<SupportedLanguageCode, CustomLang>([
 *          [LANG_EN, enLang],
 *          [LANG_FR, frLang],
 *          ...
 * ]);
 * const requiredLang = getLang(LANG_EN, languagesMap);
 * ```
 */

export function getLang<T extends CommonLang>(langCode: string, languages: Map<SupportedLanguageCode, T>): T {
    if (!languages.has(DEFAULT_LANG)) {
        throw Error(`missing default lang ${DEFAULT_LANG}`);
    }
    const defaultLang = languages.get(DEFAULT_LANG) as T;
    if (langCode === DEBUG_LANG && (process.env.REACT_APP_PLATFORM || process.env.REACT_APP_PLATFORM) !== "development") {
        return defaultLang;
    }
    switch (langCode) {
        case LANG_EN:
            return languages.get(LANG_EN) || defaultLang;
        case LANG_FR:
            return languages.get(LANG_FR) || defaultLang;
        case LANG_DE:
            return languages.get(LANG_DE) || defaultLang;
        case LANG_ES:
            return languages.get(LANG_ES) || defaultLang;
        case LANG_IT:
            return languages.get(LANG_IT) || defaultLang;
        case DEBUG_LANG:
            return keyPathAsValue(defaultLang);
        default:
            return defaultLang;
    }
}

export type PluralString = {
    [key: number]: string;
    plural?: (count: number) => string;
};

export function plural(pluralString: PluralString, count = 0): string {
    if (pluralString[count]) {
        return pluralString[count];
    }
    if (count > 1 && pluralString.plural) {
        return pluralString.plural(count);
    }
    return "";
}
