import { ColumnDef } from "@tanstack/react-table";

export type ColumnFilter<T extends object = object> = { onFilter: (row: T) => boolean; label: string; value: unknown };

export type Column<T extends object = object> = ColumnDef<T> & {
    accessorKey?: string;
    defaultSort?: "asc" | "desc";
    defaultFilteredValues?: unknown;
    filters?: ColumnFilter<T>[];
    hide?: boolean;
};
export type { CellContext } from "@tanstack/react-table";

export type TableRow<T> = T & { key?: keyof T | string; render?: (isSelected?: boolean) => JSX.Element; children?: TableRow<T>[] };

export type EmptyFilter = string | null | undefined;
export const EMPTY_VALUES = ["", null, undefined, []];

export function isExpandableRow<T extends object>(obj: T, isTree?: boolean): obj is TableRow<T> {
    return !isTree && "children" in obj;
}
