/* eslint-disable no-param-reassign */
import { AnalysedCodeRepository, FileInfo, getDepth } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { getClassWithColor } from "file-icons-js";
import filesize from "filesize";
import { Dispatch, SetStateAction, useMemo } from "react";
import {
    ArrowRight,
    Button,
    Column,
    CommonLang,
    CopyIcon,
    FileIcon,
    FolderIcon,
    GitIcon,
    Spin,
    Tables,
    Tooltip,
    copyToClipboard,
    formatAsDecimal,
    useLang
} from "../..";

type CodeRepositoryInfo = Partial<AnalysedCodeRepository> & { name: string; rootFolderPaths?: string[] };

export interface CodeRepositoryFileInfos<T extends FileInfo> {
    codeRepository: CodeRepositoryInfo;
    fileInfos: T[];
}

export interface CodeRepositoryFileInfo<T extends FileInfo> {
    codeRepository: CodeRepositoryInfo;
    fileInfo: T;
}

export type FileTreeRecord<T extends FileInfo> = T & {
    depth: number;
    isFolder: boolean;
    repository?: string;
    isAtSelectedDepth?: boolean;
    children?: FileTreeRecord<T>[];
    renamed?: string;
};

export type FileTreeProps<T extends FileInfo> = {
    codeRepositoriesFileInfos: CodeRepositoryFileInfos<T>[];
    extraColumns?: Column<FileTreeRecord<T>>[];
    selectedRows?: string[];
    setSelectedRows?: Dispatch<SetStateAction<string[]>>;
    onExpand?: (record: FileTreeRecord<T>) => Promise<FileInfo[]>;
    onFileNameClicked?: (record: FileTreeRecord<T>) => void;
    searchText?: string;
    onFilter?: (count: number) => void;
};

function sum(countA?: number, countB?: number) {
    return (countA ?? 0) + (countB ?? 0);
}

function createFolderRecord<T extends FileInfo>(name: string, path: string, depth: number, codeRepository: CodeRepositoryInfo): FileTreeRecord<T> {
    return {
        path,
        name,
        depth,
        repository: codeRepository.name,
        isFolder: true,
        children: [] as FileTreeRecord<T>[],
        lines: 0,
        size: 0,
        isAtSelectedDepth: codeRepository.rootFolderPaths?.includes(path) ?? false
    } as FileTreeRecord<T>;
}

function mapFilesToRecord<T extends FileInfo>(files: FileInfo[], root: FileTreeRecord<T>, codeRepository: CodeRepositoryInfo): FileTreeRecord<T> {
    files.forEach(file => {
        const parts = file.path.split("/").slice(1); // Remove the empty first element from splitting
        let current = root;
        // loop over filepath parts to identify the parent directories
        for (let i = 0; i < parts.length; i++) {
            const name = parts[i];
            if (!current.children) {
                current.children = [];
            }
            const depth = getDepth(file);
            if (i === parts.length - 1) {
                const path = `/${parts.join("/")}`;
                if (name) {
                    const record: FileTreeRecord<T> = {
                        ...file,
                        path,
                        name,
                        repository: codeRepository.name,
                        size: file.size ?? 0,
                        lines: file.lines ?? 0,
                        depth,
                        isFolder: false
                    } as FileTreeRecord<T>;
                    current.children.push(record);
                    // Aggregate lines and size at the file's parent directory
                    current.lines = sum(current.lines, file.lines);
                    current.size = sum(current.size, file.size);
                }
            } else {
                // Directory
                const path = `/${parts.slice(0, i + 1).join("/")}/`;
                let folder: FileTreeRecord<T> | undefined = current.children.find(child => child.path === path);
                // Create a new folder record
                if (!folder) {
                    folder = createFolderRecord(name, path, depth, codeRepository);
                    current.children.push(folder);
                }
                if (folder) {
                    current = folder;
                }
            }
        }
    });
    return root;
}

function buildTree<T extends FileInfo>(files: T[], codeRepository: CodeRepositoryInfo): FileTreeRecord<T> {
    const rootFolder = createFolderRecord<T>(codeRepository.name, "/", 0, codeRepository);
    return mapFilesToRecord(files, rootFolder, codeRepository);
}

// Sum all children stats and set it to the parent folder
function aggregateFolderSums<T extends FileInfo>(rootNode: FileTreeRecord<T>): FileTreeRecord<T> {
    rootNode.children?.forEach(child => {
        if (child.children) {
            aggregateFolderSums(child); // ensure child sums are updated first
            rootNode.lines = sum(rootNode.lines, child.lines);
            rootNode.size = sum(rootNode.size, child.size);
        }
    });
    rootNode.is = {
        binary: rootNode.children?.every(child => child.is?.binary),
        generated: rootNode.children?.every(child => child.is?.generated),
        test: rootNode.children?.every(child => child.is?.test),
        conf: rootNode.children?.every(child => child.is?.conf),
        doc: rootNode.children?.every(child => child.is?.doc),
        lang: rootNode.children?.every(child => child.is?.lang),
        thirdParty: rootNode.children?.every(child => child.is?.thirdParty)
    };
    return rootNode;
}

function getFolderColor(record: FileTreeRecord<FileInfo>): "green" | "red" | "blue" | undefined {
    if (record.isAtSelectedDepth || record.is?.test) {
        return "green";
    }
    if (record.is?.generated) {
        return "red";
    }
    if (record.path.includes("/src/")) {
        return "blue";
    }
    return undefined;
}

function orderByFolderFirst<T extends FileInfo>(a: FileTreeRecord<T>, b: FileTreeRecord<T>) {
    return Number(b.isFolder) - Number(a.isFolder);
}

function renderIcon<T extends FileInfo>(record: FileTreeRecord<T>) {
    const icon = record.name ? getClassWithColor(record.name) : null;
    if (record.isFolder) {
        const IconRecord = record.isAtSelectedDepth ? GitIcon : FolderIcon;
        return (
            <span>
                <IconRecord color={getFolderColor(record)} />
            </span>
        );
    }
    return icon ? <span className={icon} /> : <FileIcon />;
}

function renderName<T extends FileInfo>(record: FileTreeRecord<T>, lang: CommonLang, onClick?: (record: FileTreeRecord<T>) => void) {
    const path = `${record.repository}${record.path}`;

    function copyPath(e?: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>): Promise<void> {
        e?.preventDefault();
        e?.stopPropagation();
        return copyToClipboard(path);
    }

    const title = (
        <div className="flex gap-2">
            <div>{path}</div>
            <Button type="default" size="sm" children={lang.shared.copy} onClick={copyPath} icon={CopyIcon} isLoading={false} />
        </div>
    );
    return (
        <Tooltip title={title} overlayClassName="tree-filename" delayDuration="long" asChild>
            <div className="inline-flex w-full items-center gap-2 truncate text-left" {...(onClick && { onClick: () => onClick(record) })}>
                {renderIcon(record)}
                <span
                    className={classNames("w-full truncate", {
                        "line-through": record.renamed && record.isAtSelectedDepth,
                        "underline cursor-pointer": onClick
                    })}
                >
                    {record.name}
                </span>
                {record.renamed && record.isAtSelectedDepth && (
                    <span className="space-x-2 italic text-gray-400">
                        <ArrowRight color="grey" shade="light" />
                        <span>{record.renamed}</span>
                    </span>
                )}
            </div>
        </Tooltip>
    );
}

const renderSize = (size: number) => {
    if (size) {
        return filesize(size, { base: 10 });
    }
    return null;
};

export default function FileTree<T extends FileInfo>({
    codeRepositoriesFileInfos,
    extraColumns = [] as Column<FileTreeRecord<T>>[],
    selectedRows,
    setSelectedRows,
    onExpand,
    onFileNameClicked,
    searchText,
    onFilter
}: FileTreeProps<T>): JSX.Element {
    const lang = useLang();
    const data = useMemo(
        () => codeRepositoriesFileInfos.flatMap(({ fileInfos, codeRepository }) => buildTree<T>(fileInfos, codeRepository)).map(aggregateFolderSums) || [],
        [codeRepositoriesFileInfos]
    );

    const columns: Column<FileTreeRecord<T>>[] = [
        {
            header: lang.shared.name,
            accessorKey: "name",
            sortingFn: (a, b) => orderByFolderFirst(a.original, b.original) || (a.original.name ?? "").localeCompare(b.original.name ?? ""),
            defaultSort: "asc",
            cell: cell => renderName(cell.row.original, lang, cell.row.original.isFolder ? undefined : onFileNameClicked)
        },
        {
            header: lang.fileTree.lines,
            accessorKey: "lines",
            sortingFn: (a, b) => orderByFolderFirst(a.original, b.original) || (a.original.lines ?? 0) - (b.original.lines ?? 0),
            cell: cell => (cell.getValue<number>() ? formatAsDecimal(cell.getValue<number>()) : null)
        },
        {
            header: lang.fileTree.size,
            accessorKey: "size",
            sortingFn: (a, b) => orderByFolderFirst(a.original, b.original) || (a.original.size ?? 0) - (b.original.size ?? 0),
            cell: cell => renderSize(cell.getValue<number>())
        },
        ...extraColumns
    ];

    async function onLoadChildren(record: FileTreeRecord<T>): Promise<FileTreeRecord<T>[]> {
        if (!onExpand) {
            return [];
        }

        const currentRepository = codeRepositoriesFileInfos.find(({ codeRepository }) => codeRepository.name === record.repository)?.codeRepository;
        if (currentRepository) {
            const children = await onExpand(record);
            return mapFilesToRecord(children, record, currentRepository).children?.[0]?.children ?? [];
        }
        return [];
    }

    if (!data) {
        return <Spin />;
    }

    return (
        <div className="h-full overflow-y-auto">
            <Tables.Tree<FileTreeRecord<T>>
                data={data}
                columns={columns}
                onLoadChildren={onExpand ? onLoadChildren : undefined}
                searchText={searchText}
                onFilter={onFilter}
                isVirtualized
                itemKey="path"
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
            />
        </div>
    );
}
