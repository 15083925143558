import { prependIndex, Routes } from "@vaultinum/vaultinum-sdk";

export const APP_PATH = process.env.PUBLIC_URL as "/app";
export const ALLOWED_DOMAIN = "vaultinum.com";
export const ALLOWED_SUBDOMAIN_SUFFIX = ".vaultinum.net";

export const PATH = {
    index: `${APP_PATH}/*`,
    // public pages
    login: "/login",
    register: "/register",
    forgotPassword: "/forgot-password",
    authAction: "/auth-action",
    // private pages
    verify: "/verify",
    welcome: "/welcome",
    account: {
        index: "/account/*",
        new: "/new",
        all: "/*"
    },
    domainOrganisations: {
        index: "/organisations/*",
        id: "/:id",
        all: "/*"
    },
    settings: {
        index: "/settings/*",
        all: "/*"
    },
    all: "/*"
} as const satisfies Routes;

export const URL = prependIndex(PATH);
